import { action, computed, makeObservable, observable } from 'mobx';
import { ColumnType } from '@/utils/types';

const BULK_HEADER_COLUMNS = [
  {
    key: ColumnType.select,
    label: '',
    align: 'left',
    width: 28,
  },
  {
    key: ColumnType.id,
    label: 'Id',
    sortKey: 'id',
    align: 'left',
    width: 72,
  },
  {
    key: ColumnType.organization,
    label: 'Organization',
    sortKey: ColumnType.organization,
    align: 'left',
    width: 100,
  },
  {
    key: ColumnType.workflow,
    label: 'Workflow',
    sortKey: ColumnType.workflow,
    align: 'left',
    width: 100,
  },
  {
    key: ColumnType.title,
    label: 'Title',
    sortKey: ColumnType.title,
    align: 'left',
    width: 100,
  },
  {
    key: ColumnType.state,
    label: 'State',
    sortKey: ColumnType.state,
    align: 'left',
    width: 100,
  },
  {
    key: ColumnType.resolved,
    label: 'Resolved',
    sortKey: ColumnType.resolved,
    align: 'left',
    width: 100,
  },
  {
    key: ColumnType.total,
    label: 'Total',
    sortKey: ColumnType.total,
    align: 'left',
    width: 100,
  },
  {
    key: ColumnType.lastUpdated,
    label: 'Last Updated',
    sortKey: ColumnType.lastUpdated,
    align: 'left',
    width: 100,
  },
  {
    key: ColumnType.ownedByName,
    label: 'Owned By',
    sortKey: ColumnType.ownedByName,
    align: 'left',
    width: 100,
  },
  {
    key: ColumnType.assignedToName,
    label: 'Assigned To',
    sortKey: ColumnType.assignedToName,
    align: 'left',
    width: 100,
  },
  {
    key: ColumnType.comentedByName,
    label: 'Last Comment By',
    sortKey: ColumnType.comentedByName,
    align: 'left',
    width: 100,
  },
];

export class TableStore {
  hoveredRow = null;
  selectedRow = null;
  rowsPerPage = 100;
  pageNumber = 1;
  sortKey = 'updatedOn';
  sortDirection = 'desc';

  constructor(accountStore, filterStore) {
    makeObservable(this, {
      hoveredRow: observable,
      onMouseEnter: action,
      onMouseLeave: action,
      
      pageNumber: observable,
      setPageNumber: action,

      selectedRow: observable,
      setSelectedRow: action,

      sortKey: observable,
      setSortKey: action,
      sortDirection: observable,
      setSortDirection: action,

      rowsPerPage: observable,
      setRowsPerPage: action,
    });

    this.accountStore = accountStore;
    this.filterStore = filterStore;
  }

  setSortKey(key) {
    if (key) {
      this.sortKey = key;
    }
  }

  setSortDirection(dir) {
    if (dir) {
      this.sortDirection = dir;
    }
  }

  // NOTE: Get table header columns
  get bulkHeaderColumns() {
    return BULK_HEADER_COLUMNS;
  }

  // NOTE: Table actions
  onMouseEnter(logId) {
    this.hoveredRow = logId;
  }

  onMouseLeave() {
    this.hoveredRow = null;
  }

  setSelectedRow(logId) {
    this.selectedRow = logId;
  }

  // NOTE: Sort table rows by order
  toggleSort(sortKey) {
    let direction = 'asc';
    if (this.sortKey === sortKey) {
      direction = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      direction = 'desc';
    }
    this.sortKey = sortKey;
    this.sortDirection = direction;
  }

  // NOTE: Check if current column is active
  isActiveSort(sortKey) {
    return this.sortKey === sortKey;
  }

  getSortDirection(sortKey) {
    if (this.sortKey === sortKey) return this.sortDirection;
    return 'asc';
  }

  // NOTE: Pagination
  setRowsPerPage(rows) {
    this.rowsPerPage = rows;
  }

  setPageNumber(page) {
    this.pageNumber = page;
  }

  dispose() {
    // TBD
  }
}
