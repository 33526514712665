import { useState, useEffect } from 'react';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { TextField, Button as MuiButton, Grid } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Button, Icon, Typography, TypeaheadDropdown } from '@/components';
import { downloadFile, getAssignFromId, getFileSizeError, getFileType, isStorybook } from '@/utils';
import { SaveAltIcon, DeleteIcon } from '@/assets/icons';
import ImageIcon from '@/assets/icons/ImageFile.svg';
import { useStyles } from './styles';

const PAGE_NAME = 'SubmitEdit';

const SubmitEdit = ({
  containerClassName,
  title,
  attachments,
  initAttachments,
  fullWidth,
  isDarkTheme,
  isSubmit,
  multiline,
  required,
  isBorder,
  updateTitle,
  updateAttachments,
  onCancel,
  onSubmit,
  assignTo,
  assignOptions,
  updateAssignTo,
  maxLength,
  ...rest
}) => {
  const styles = useStyles();
  const [currentValue, setCurrentValue] = useState('');
  const [addedFiles, setAddedFiles] = useState([]);
  const [hoveredFile, setHoveredFile] = useState(null);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setCurrentValue(title || '');
    setAddedFiles(attachments || []);
  }, [title, attachments]);

  useEffect(() => {
    if (required === 'both') {
      setDisabled(!currentValue || addedFiles.length === 0);
    } else if (required === 'title') {
      setDisabled(!currentValue);
    } else if (required === 'one') {
      setDisabled(!currentValue && addedFiles.length === 0);
    } else {
      setDisabled(false);
    }
    if (getFileSizeError(addedFiles)) {
      setDisabled(true);
    }
  }, [currentValue, addedFiles, required]);

  const handleChange = (event) => {
    setCurrentValue(event.target.value);
    if (updateTitle) {
      updateTitle(event.target.value);
    }
  };

  const handleAttachedFile = (event) => {
    if (event.target.files?.length) {
      let newFiles = addedFiles;
      for (const file of event.target.files) {
        if (file.type.includes('image')) {
          file.url = URL.createObjectURL(file);
        }
        newFiles = newFiles.concat(file);
      }
      setAddedFiles(newFiles);
      if (updateAttachments) updateAttachments(newFiles);
      event.target.value = '';
    }
  };

  const handleDeleteFile = (file) => {
    const newFiles = addedFiles.filter((item) => !isEqual(file, item));
    setAddedFiles(newFiles);
    if (updateAttachments) updateAttachments(newFiles);
  };

  const handleSubmit = () => {
    const deletingFiles = initAttachments
      ?.filter((item) => !addedFiles?.find((file) => file.name === item.name))
      .filter((file) => file.id);
    const addingFiles = addedFiles
      ?.filter((item) => !initAttachments?.find((file) => file.name === item.name))
      ?.filter((file) => !file.id);
    onSubmit(currentValue, addingFiles, deletingFiles, assignTo);
    setCurrentValue('');
    setAddedFiles([]);
  };

  const renderAssign = (value) => {
    const assignItem = getAssignFromId(value, assignOptions);
    if (!assignItem || !assignItem.label)
      return (
        <Typography variant="body1" id={`${PAGE_NAME}_assignValue`}>
          Select Assignee
        </Typography>
      );
    return <Typography variant="body1">{assignItem.label || 'None'}</Typography>;
  };

  return (
    <div className={styles.submitEditRoot}>
      <div
        id={`${PAGE_NAME}_container_div`}
        className={cx(styles.submitEditContainer, containerClassName)}
      >
        <div
          className={cx(styles.submitEditWrapper, {
            [styles.submitEditWrapperDark]: isDarkTheme,
            [styles.fullWidth]: fullWidth,
            [styles.outerBorder]: isBorder,
            [styles.submitEditWrapperError]: getFileSizeError(addedFiles),
          })}
          style={{ maxHeight: (window.innerHeight - 170) / 2, overflow: 'auto' }}
        >
          <div className={styles.contentWrapper}>
            <TextField
              {...rest}
              value={currentValue}
              multiline={multiline}
              id={`${PAGE_NAME}_textField`}
              variant="standard"
              inputProps={{ maxLength }}
              classes={{
                root: cx(styles.submitEdit, {
                  [styles.textFieldLight]: !isDarkTheme,
                  [styles.submitEditMultiline]: multiline,
                }),
                focused: styles.textFieldInputFocus,
              }}
              onChange={handleChange}
            />

            {addedFiles.length > 0 && (
              <Grid
                container
                className={cx(styles.attachedFiles, {
                  [styles.leftAlignItem]: addedFiles.length < 2,
                })}
              >
                {addedFiles.map((file) => (
                  <Grid key={file.id || file.name} item xs={6}>
                    <div id={`${PAGE_NAME}_AttachedFiles_fileInfo`} className={styles.attachment}>
                      <div
                        className={styles.imageContainer}
                        onMouseEnter={() => setHoveredFile(file)}
                        onMouseLeave={() => setHoveredFile(null)}
                        onClick={() => downloadFile(file)}
                      >
                        {getFileType(file.name) === 'image' ? (
                          <img
                            className={isStorybook() ? styles.storybookPreview : styles.imgPreview}
                            src={file.preview || file.url}
                            alt=" "
                            onError={(event) => {
                              event.target.src = ImageIcon;
                              event.onerror = null;
                              event.target.className = '';
                            }}
                          />
                        ) : (
                          <Icon type="doc" width={64} height={64} />
                        )}

                        {isEqual(hoveredFile, file) && (
                          <div className={styles.hoveredFile}>
                            <SaveAltIcon className={styles.downloadIcon} />
                          </div>
                        )}

                        <DeleteIcon
                          id={`${PAGE_NAME}_AttachedFiles_fileIcon`}
                          className={styles.deleteFileIcon}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteFile(file);
                          }}
                        />
                      </div>
                      <span
                        className={styles.fileName}
                        onMouseEnter={() => setHoveredFile(file)}
                        onMouseLeave={() => setHoveredFile(null)}
                        onClick={() => downloadFile(file)}
                      >
                        {file.name}
                      </span>
                    </div>
                  </Grid>
                ))}
              </Grid>
            )}
          </div>
          <div className={styles.attachButtonContainer}>
            <MuiButton
              component="label"
              id={`${PAGE_NAME}_attach_button`}
              classes={{ root: styles.attachButton }}
            >
              <AttachFileIcon className={styles.attachIcon} />
              <input
                type="file"
                multiple
                accept=""
                onChange={handleAttachedFile}
                className={styles.customFileInput}
              />
            </MuiButton>
          </div>
        </div>
        {assignTo && (
          <TypeaheadDropdown
            id={`${PAGE_NAME}_taskOwner_dropdown`}
            value={assignTo}
            options={assignOptions}
            classes={{
              root: styles.flexNone,
              container: styles.assignContainer,
              svgIcon: styles.moreIcon,
            }}
            renderValue={renderAssign}
            onChange={updateAssignTo}
          />
        )}
        {isSubmit && (
          <Button
            id={`${PAGE_NAME}_submit_button`}
            variant="primary"
            size="small"
            disabled={disabled}
            onClick={handleSubmit}
          >
            {rest.children || 'Submit'}
          </Button>
        )}
        {onCancel && (
          <Button
            id={`${PAGE_NAME}_cancel_button`}
            variant="tertiary"
            size="large"
            onClick={onCancel}
          >
            Cancel
          </Button>
        )}
      </div>
      {getFileSizeError(addedFiles) && (
        <Typography variant="subtitle2" className={styles.errorLabel}>
          File size exceeded limit (20MB).
        </Typography>
      )}
    </div>
  );
};

SubmitEdit.propTypes = {
  fullWidth: PropTypes.bool,
  isDarkTheme: PropTypes.bool,
  isSubmit: PropTypes.bool,
  multiline: PropTypes.bool,
  required: PropTypes.oneOf(['none', 'one', 'title', 'all']),
  isBorder: PropTypes.bool,
  maxLength: PropTypes.number,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

SubmitEdit.defaultProps = {
  fullWidth: true,
  isDarkTheme: false,
  isSubmit: true,
  required: 'none',
  isBorder: false,
  maxLength: 2048,
  multiline: true,
  onCancel: undefined,
};

export default SubmitEdit;
