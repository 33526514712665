import { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import './DataGridTable.css';

export default function DataGridTable({
  id,
  rows,
  columns,
  rowHeight = 30,
  columnHeaderHeight = 33,
  classNameBox = '',
  ...otherProps
}) {
  const saveColumnWidthKey = `${id}-columnWidths`;
  const [columnWidths, setColumnWidths] = useState(() => {
    const savedWidths = localStorage.getItem(saveColumnWidthKey);
    return savedWidths ? JSON.parse(savedWidths) : {};
  });

  const columnsWithWidths = useMemo(
    () =>
      columns.map((column) => ({
        ...column,
        flex: column.flex && !columnWidths[column.field] ? column.flex : undefined,
        width: columnWidths[column.field] || column.width,
      })),
    [columns],
  );
  useEffect(() => {
    // Save column widths to local storage whenever they change
    localStorage.setItem(saveColumnWidthKey, JSON.stringify(columnWidths));
  }, [columnWidths]);

  const handleColumnWidthChange = (params) => {
    setColumnWidths((prevWidths) => ({
      ...prevWidths,
      [params.colDef.field]: params.width,
    }));
  };

  return (
    <Box className={`tableContainer ${classNameBox}`}>
      <DataGrid
        sx={{
          "& [class*='MuiDataGrid-filler']": { display: 'none' },
        }}
        rows={rows}
        columns={columnsWithWidths}
        onColumnWidthChange={handleColumnWidthChange}
        rowHeight={rowHeight}
        columnHeaderHeight={columnHeaderHeight}
        disableColumnMenu
        classes={{
          scrollbar: 'scrollArea',
          virtualScroller: 'customVirtualScroll',
          selected: 'customSelected',
        }}
        {...otherProps}
      />
    </Box>
  );
}
