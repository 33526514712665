import { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';

import { DetailTicketContent } from '../main/components/DetailTicketContent';
import { useMainStore } from '../main/stores/useMainStore';
import { BASE_ROUTES, UrlSearch } from '@/utils/types';
import { LoadingIndicator } from '@/components';
import { accountStore } from '@/store/AccountStore';
import { PAGE_MODE } from '@/utils/constants';

import styles from './TicketDetailPage.module.css';

const TicketDetailPage = () => {
  const { detailStore } = useMainStore();
  const navigate = useNavigate();
  const { ticketId } = useParams();

  useEffect(() => {
    if (accountStore.allAccounts.length) {
      detailStore.setSelectedTicket(Number(ticketId), !detailStore.selectedId);
    }
  }, [accountStore.allAccounts.length]);

  useEffect(() => {
    if (accountStore.allAccounts.length && detailStore.organizationId) {
      accountStore.onSelectAccount(detailStore.organizationId);
    }
  }, [accountStore.allAccounts, detailStore.organizationId]);

  const onClose = useCallback(() => {
    const orgId = detailStore.organizationId;

    detailStore.setSelectedTicket();
    detailStore.setPageMode(PAGE_MODE.none);

    navigate(`${BASE_ROUTES.main}?${UrlSearch.organization}=${orgId}`);
  }, []);

  if (accountStore.isLoading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <div className={styles.content}>
      <DetailTicketContent onClose={onClose} />
    </div>
  );
};

export default observer(TicketDetailPage);
