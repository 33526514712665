import { action, makeObservable, observable, reaction, runInAction } from 'mobx';
import { userStore } from '@/store';
import { getAssignFromId, getCurrentLocalDateTime, getFiltersQuery } from '@/utils';
import { taskLogApi } from '@/api';
import { BASE_ROUTES, ColumnType } from '@/utils/types';
import { setError } from '@/utils/errors';

export class TaskLogStore {
  users = null;
  parsedLogs = [];
  pageCount = 1;
  isLoading = false;
  timeout = null;

  constructor(accountStore, tableStore, filterStore) {
    makeObservable(this, {
      users: observable,
      parsedLogs: observable,
      pageCount: observable,

      isLoading: observable,
      setIsLoading: action,
    });

    this.accountStore = accountStore;
    this.tableStore = tableStore;
    this.filterStore = filterStore;

    this.disposeResetPagination = reaction(
      () => [this.accountStore.selectedAccount?.id],
      () => this.resetPagination(),
      { fireImmediately: true },
    );
  }

  // NOTE: Get assign info
  getPicture(userId) {
    return this.users.find((user) => user.id === userId)?.picture;
  }

  // NOTE: Reset pagination settings
  resetPagination() {
    this.tableStore.setPageNumber(1);
  }

  updateAdvancedLogs(logs) {
    this.parsedLogs = logs.map((log) => {
      const organization = this.accountStore.getAccountFromId(
        this.accountStore.allAccounts,
        log.organizationId,
      )?.name;
      const assignee = getAssignFromId(log.assignedTo, userStore.assignOptions);
      const owner = getAssignFromId(log.ownedBy, userStore.assignOptions);
      return {
        ...log,
        [ColumnType.ownedByName]: owner.label,
        [ColumnType.ownerType]: owner.type,
        [ColumnType.assignedName]: assignee.label,
        [ColumnType.assignType]: assignee.type,
        organization,
        accountName: organization,
      };
    });
  }

  // NOTE: Fetch Log list
  async fetchTaskLog(accountId, query, sort) {
    if (!accountId) return;
    this.setIsLoading(true);
    try {
      const urlParams = window.location.search;
      const res = await taskLogApi.getTaskLogs(accountId, query, sort);

      runInAction(() => {
        userStore.setUrlParams(BASE_ROUTES.main, urlParams);
        this.pageCount = Math.ceil(res.pageCount / this.tableStore.rowsPerPage);
        if (this.tableStore.pageNumber > this.pageCount) {
          this.tableStore.setPageNumber(1);
        }
        this.filterStore.filteredSearch = this.filterStore.search;
        this.updateAdvancedLogs(res.logs);
      });
    } catch (err) {
      setError(err, false);
    }
    this.setIsLoading(false);
  }

  async exportToCSV() {
    const sort = {
      key: this.tableStore.sortKey,
      dir: this.tableStore.sortDirection,
    };
    const query = getFiltersQuery(
      this.tableStore.pageNumber,
      this.tableStore.rowsPerPage,
      this.filterStore.filteredResult,
      this.filterStore.search,
      this.filterStore.hasIssuesFilter,
    );

    try {
      const downloadLink = await taskLogApi.downloadLogsCSV(
        this.accountStore.selectedAccount.id,
        query,
        sort,
      );
      if (downloadLink) {
        const a = document.createElement('a');
        a.href = downloadLink;
        a.download = `AdminTicketLogs-${getCurrentLocalDateTime('YYYY-MM-DD-HH-mm')}.csv`;
        a.click();
      }
    } catch (err) {
      setError(err, false, 'Ticket logs CSV download failed');
    }
  }

  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  dispose() {
    this.disposeResetPagination();
    this.tableStore.dispose();
    this.filterStore.dispose();
  }
}
