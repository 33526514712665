import PropTypes from 'prop-types';
import { Autocomplete, Box, TextField } from '@mui/material';

import styles from './ComboBox.module.css';

const ComboBox = ({ options, label, error, placeholder, disabled, onChange, value, ...rest }) => {
  return (
    <Autocomplete
      freeSolo
      disableClearable
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={label}
          InputProps={{
            ...params.InputProps,
            classes: {
              root: styles.textField,
              input: styles.textField,
              notchedOutline: styles.noBorder,
            },
          }}
          className={styles.textField}
          onChange={(e) => onChange(e.target.value)}
        />
      )}
      renderOption={(props, { label }) => {
        const { key, ...optionProps } = props;
        return (
          <Box component="li" {...optionProps}>
            {label}
          </Box>
        );
      }}
      value={value}
      onChange={(_, value) => onChange(value)}
      disabled={disabled}
      classes={{
        paper: styles.assignPaper,
        root: styles.autoComplete,
        option: styles.item,
        focused: styles.autoCompleteSelected,
      }}
    />
  );
};

ComboBox.propTypes = {
  label: PropTypes.string,
  error: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.any, label: PropTypes.string })),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.string,
};

ComboBox.defaultProps = {
  label: '',
  error: false,
  placeholder: '',
  disabled: false,
  options: [],
  onChange: () => {},
};

export default ComboBox;
