import { useState, useEffect, useCallback, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { cloneDeep, isEqual, omit } from 'lodash';

import { LoadingIndicator, ResizeGroupPanel } from '@/components';
import { Header } from './Header';
import MemberTable from './MemberTable';
import DetailContent from './DetailContent';
import { useSettingsStore, userStore } from '@/store';
import { BASE_ROUTES, NotificationText, TRISTATE, UrlSearch } from '@/utils/types';
import { FilterType, PAGE_MODE } from '@/utils/constants';
import { InitUserFilters, getFullName } from '@/utils/users';
import { USERS_TABLE_RESIZE_SAVE_KEY } from '@/utils';

import styles from './Members.module.css';

const PAGE_NAME = 'MemberPage';

const Members = () => {
  const { accountStore, membersStore, routerStore } = useSettingsStore();
  const [pageMode, setPageMode] = useState(PAGE_MODE.none);
  const editingMember = membersStore.firstSelectedUser;
  const initLoadingRef = useRef(true);
  const prevParams = useRef(null);
  const urlParams = routerStore.getSearchChunkParams();
  const isCreating = pageMode === PAGE_MODE.creating;

  useEffect(() => {
    const pageSettings = userStore.getPageSettings(BASE_ROUTES.users);
    if (pageSettings.columns?.length)
      membersStore.updateFilterValues(FilterType.viewBy, pageSettings.columns);
  }, []);

  useEffect(() => {
    const fetchAccounts = async () => {
      const result = await accountStore.fetchAccounts();
      if (result) membersStore.fetchAllAccounts(cloneDeep(result));
      membersStore.fetchMembers();
    };
    fetchAccounts();
  }, [userStore.organizationId, userStore.isRefresh]);

  useEffect(() => {
    if (routerStore.currentPage !== BASE_ROUTES.users) {
      routerStore.setCurrentPage(BASE_ROUTES.users);
      return;
    }
    if (prevParams.current && isEqual(prevParams.current, urlParams)) return;
    if (membersStore.allAccounts.length === 0) return;
    prevParams.current = urlParams;
    userStore.setUrlParams(BASE_ROUTES.users, window.location.search);

    // NOTE: Update table
    const pageNumber = Number(urlParams[UrlSearch.page]);
    const pageRows = Number(urlParams[UrlSearch.pageRows]);
    if (pageNumber && pageRows) {
      membersStore.setPageNumber(pageNumber);
      membersStore.setPageRows(pageRows);
    }
    const sorts = urlParams[UrlSearch.sort]?.split('+');
    if (sorts?.[0] && sorts?.[1]) membersStore.setSortColumn(sorts?.[0], sorts?.[1]);

    // NOTE: Update filters
    membersStore.updateAllSelectedValues({
      [FilterType.status]:
        urlParams[FilterType.status]
          ?.split('+')
          .map((value) => Number(value) % 3 || TRISTATE.unchecked) ?? [],
    });
    const searchTxt = urlParams[UrlSearch.search];
    membersStore.setSearch(searchTxt || '');
    initLoadingRef.current = false;
  }, [membersStore.allAccounts, urlParams]);

  useEffect(() => {
    if (initLoadingRef.current) return;

    // Update Uri
    const params = omit(
      routerStore.getSearchChunkParams(),
      Object.keys(InitUserFilters).concat(UrlSearch.search, FilterType.viewBy),
    );
    params[UrlSearch.page] = membersStore.currentPage;
    params[UrlSearch.pageRows] = membersStore.pageRows;
    params[
      UrlSearch.sort
    ] = `${membersStore.currentSort.name}+${membersStore.currentSort.direction}`;

    const filters = membersStore.filteredResult;
    Object.keys(filters).forEach((filterKey) => {
      const value = filters[filterKey]?.selectedValues;
      if (value?.length > 0 && filterKey !== FilterType.viewBy) {
        params[filterKey] = value.join('+');
      }
    });
    if (membersStore.search) {
      params[UrlSearch.search] = membersStore.search;
    }

    const stringParams = Object.keys(params).reduce((acc, key) => {
      if (Number.isFinite(params[key])) return { ...acc, [key]: params[key].toString() };
      return { ...acc, [key]: params[key] };
    }, {});

    if (!isEqual(urlParams, stringParams)) {
      routerStore.setSearchChunkParams(params);
    }

    if (
      !prevParams.current ||
      !isEqual(params, prevParams.current.params) ||
      prevParams.current.sortKey !== membersStore.currentSort.name ||
      prevParams.current.sortDir !== membersStore.currentSort.direction
    ) {
      prevParams.current = {
        params,
        sortKey: membersStore.currentSort.name,
        sortDir: membersStore.currentSort.direction,
        search: membersStore.search,
      };
      userStore.setUrlParams(BASE_ROUTES.users, window.location.search);
    }
  }, [
    membersStore.filteredResult,
    membersStore.search,
    membersStore.currentPage,
    membersStore.pageRows,
    membersStore.currentSort,
  ]);

  useEffect(() => {
    if (!initLoadingRef.current) membersStore.setPageNumber(1);
  }, [membersStore.search, membersStore.allFilteredCount]);

  const saveUser = async (user) => {
    const organization = membersStore.getAccountFromId(
      membersStore.allAccounts,
      user.organizationId,
    );
    const organizationPath = organization
      ? organization.grandParentPath.concat(organization.path).join('/')
      : `${user.organizationId}`;
    const userInfo = { ...user, name: getFullName(user), organizationPath };
    const resUser = await membersStore.createSaveUser(
      userInfo,
      editingMember ? NotificationText.updateUserError : NotificationText.createUserError,
    );
    if (resUser) {
      membersStore.setSelectedUser({
        ...resUser,
        organizationPathByName: organization.pathByName,
      });
      setPageMode(PAGE_MODE.none);
    }
  };

  const handleSelectUser = useCallback(
    async (user) => {
      if (user && pageMode !== PAGE_MODE.loading) {
        setPageMode(PAGE_MODE.loading);
        membersStore.onSelectUser(user.id);
      }
    },
    [pageMode],
  );

  if (accountStore.isLoading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <div className={styles.mainLayout}>
      {membersStore.isLoading && <LoadingIndicator fullScreen />}
      {!membersStore.isLoading && (
        <div id={`${PAGE_NAME}_settingsContainer`} className={styles.memberSettings}>
          <Header onCreateUser={() => setPageMode(PAGE_MODE.creating)} />
          <ResizeGroupPanel
            saveId={USERS_TABLE_RESIZE_SAVE_KEY}
            firstPanel={<MemberTable onSelectUser={handleSelectUser} />}
            showLastPanel={Boolean(editingMember) || isCreating}
            initialFirstSize={60}
          >
            <DetailContent
              pageMode={pageMode}
              setPageMode={setPageMode}
              defaultOrgId={isCreating ? membersStore.organization.id : null}
              saveUser={saveUser}
              selectedUsers={membersStore.selectedUsers}
            />
          </ResizeGroupPanel>
        </div>
      )}
    </div>
  );
};

export default observer(Members);
