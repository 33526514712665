import { useState } from 'react';
import clsx from 'clsx';

import { Divider, Typography } from '@mui/material';
import { CollapseButton } from '@/components';

import styles from './CollapsibleSection.module.css';

export const CollapsibleSection = ({
  title,
  children,
  defaultExpanded = false,
  withDivider = true,
  className,
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  return (
    <div className={styles.container}>
      <div className={clsx(styles.header, className)}>
        <Typography className={styles.title}>{title}</Typography>
        <CollapseButton isCollapse={!isExpanded} onClick={() => setIsExpanded(!isExpanded)} />
      </div>
      {isExpanded && <div className={styles.content}>{children}</div>}
      {withDivider && <Divider className={styles.divider} />}
    </div>
  );
};
