import { EmojiIcons } from '@/utils/types';
import './CollapseButton.css';

export const CollapseButton = ({ isCollapse, onClick }) => {
  return (
    <div className="collapseButton" onClick={onClick}>
      {isCollapse ? EmojiIcons.up : EmojiIcons.down}
    </div>
  );
};
