import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { StoreContext, useSettingsStore } from './useSettingsStore';
import { userStore } from './UserStore';

export function withStore(WrappedComponent) {
  return (props) => {
    const store = useSettingsStore();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
      return () => {
        store.dispose();
        userStore.dispose();
      };
    }, []);

    useEffect(() => {
      store.routerStore.reInitSearch(searchParams, setSearchParams);
    }, [searchParams, setSearchParams]);

    return (
      <StoreContext.Provider value={store}>
        <WrappedComponent {...props} />
      </StoreContext.Provider>
    );
  };
}
