import { BASE_ROUTES, ObjectType } from './types';

export function getObjectType(obj) {
  if (obj.isCase || obj.externalId) return ObjectType.case;
  if (obj.isQuestion || obj.value?.includes(ObjectType.question)) return ObjectType.question;
  if (obj.isRequest) return ObjectType.request;
  return ObjectType.task;
}

export function getTicketLink(ticketId) {
  return `${BASE_ROUTES.detail}/${ticketId}`;
}
