import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import {
  LoadingIndicator,
  TablePagination,
  Pagination,
  EmptyFilterView,
  DataGridTable,
  Checkbox,
} from '@/components';
import { useSettingsStore } from '@/store';
import { HEADER_COLUMNS, USER_COLUMN_KEYS } from '@/utils/users';

import styles from './MemberTable.module.css';
import { FilterType } from '@/utils/constants';

const PAGE_NAME = 'MemberTable';

const MemberTable = ({ onSelectUser }) => {
  const { membersStore } = useSettingsStore();
  const columns = useMemo(
    () => [
      {
        field: USER_COLUMN_KEYS.select,
        headerName: '',
        sortable: false,
        width: 32,
        minWidth: 32,
        renderHeader: () => {
          return (
            <Checkbox
              checked={membersStore.isSelectedAll}
              indeterminate={membersStore.isIndeterminate}
              onChange={(e) => membersStore.onSelectAllUser(e.target.checked)}
            />
          );
        },
        renderCell: (params) => (
          <div
            className={styles.checkboxCell}
            onClick={(e) => {
              e.stopPropagation();
              membersStore.onToggleSelectUser(params.id);
              if (membersStore.selectedUsers.length === 1)
                onSelectUser(membersStore.selectedUsers[0]);
            }}
          >
            {membersStore.selectedUserIds.includes(params.id) ? '✓' : ''}
          </div>
        ),
      },
      ...HEADER_COLUMNS.filter(({ field }) => membersStore.hasColumn(field)),
    ],
    [membersStore.selectedUserIds, membersStore.filters[FilterType.viewBy].selectedValues],
  );

  return (
    <div className={styles.memberTable}>
      {membersStore.isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          {membersStore.allFilteredMembers.length === 0 &&
          (membersStore.search || membersStore.allFilteredCount > 0) ? (
            <EmptyFilterView
              onClick={() => {
                membersStore.clearFilter();
              }}
              id="memberPage_noFilteredLog"
            />
          ) : (
            <>
              <DataGridTable
                id="memberTable"
                rows={membersStore.filteredCurrentPageMembers}
                columns={columns}
                rowSelectionModel={membersStore.selectedUserIds}
                onRowClick={(row) => {
                  membersStore.selectUsers([row.id]);
                  onSelectUser(row);
                }}
                hideFooter
                checkboxSelection
                classNameBox={styles.tableContainer}
                columnVisibilityModel={{
                  __check__: false,
                }}
              />
              <div className={styles.pagination}>
                <TablePagination
                  label="View Per Page"
                  id={`${PAGE_NAME}_paginationDropdown`}
                  value={membersStore.pageRows}
                  options={[10, 20, 50, 100, 1000]}
                  onChangeRowsPerPage={(v) => membersStore.setPageRows(v)}
                />
                <Pagination
                  count={membersStore.paginationCounts}
                  page={membersStore.currentPage}
                  onChange={(e, v) => membersStore.setPageNumber(v)}
                  id={`${PAGE_NAME}_pagination`}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default observer(MemberTable);
