import React from 'react';
import { Config } from './config';
import { Auth0Provider } from '@auth0/auth0-react';
import { getStoredAccessToken } from './utils';

const EXPIRATION_TIMEOUT = 60; //  seconds

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = Config.REACT_APP_AUTH0_DOMAIN;
  const clientId = Config.REACT_APP_AUTH0_CLIENT_ID;
  if (getStoredAccessToken(EXPIRATION_TIMEOUT)) return children;

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      audience={`https://${domain}/api/v2/`}
      scope="read:current_user update:current_user_metadata"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
