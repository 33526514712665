import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { ResizeGroupPanel } from '@/components';
import { userStore } from '@/store';
import { accountStore } from '@/store/AccountStore';

import styles from './MainContainer.module.css';

const MainContainer = ({ leftSide, children }) => {
  const maxSizeInPixels = 450;
  const minSizeInPixels = 250;
  const pageName = window.location.pathname.split('/')[1] || '';

  const [sizes, setSizes] = useState({
    firstPanelMaxSize: (maxSizeInPixels / window.innerWidth) * 100,
    firstPanelMinSize: (minSizeInPixels / window.innerWidth) * 100,
  });

  const calculatePercentage = (pixels) => (pixels / window.innerWidth) * 100;

  useEffect(() => {
    const handleResize = () => {
      setSizes({
        firstPanelMaxSize: calculatePercentage(maxSizeInPixels),
        firstPanelMinSize: calculatePercentage(minSizeInPixels),
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    accountStore.fetchAccounts();
  }, [userStore.organizationId, userStore.isRefresh]);

  return (
    <div id="Organizations_container" className={styles.mainContainer}>
      <ResizeGroupPanel
        saveId={`help-admin-${pageName}-container`}
        direction="horizontal"
        initialFirstSize={sizes.firstPanelMinSize}
        firstPanel={leftSide}
        classes={{ firstPanel: styles.resizeGroupPanel }}
        firstPanelMaxSize={sizes.firstPanelMaxSize}
      >
        {children}
      </ResizeGroupPanel>
    </div>
  );
};

export default observer(MainContainer);
