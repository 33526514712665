import { Typography } from '@/components';
import styles from './EmptyLogView.module.css';

const PAGE_NAME = 'EmptyLogView';
export const EmptyLogView = () => {
  return (
    <div id={`${PAGE_NAME}_container`} className={styles.noLogItemsWrapper}>
      <Typography variant="h5" className={styles.noLogItemsLabel}>
        There aren’t any tickets for this organization yet.
      </Typography>
    </div>
  );
};
