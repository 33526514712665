import { orderBy, isEmpty, isString } from 'lodash';

import { userStore } from '../store';
import { CASE_OPERATION_TYPES, CREATE_CASE_TYPES } from '../utils/constants';
import {
  AmazonBrandRegistry,
  AmazonSellerCentral,
  AmazonVendorCentral,
  GeneralPlatform,
  GoogleAds,
} from '../components/PlatformLogins';
import { WORKFLOW_CONFIGS } from './workflows';

export const PLATFORMS = {
  none: 0,
  AmazonSellerCentral: 1,
  AmazonVendorCentral: 2,
  AmazonBrandRegistry: 4,
  GoogleAds: 6,
};

const PLATFORM_MAPS = {
  [PLATFORMS.AmazonSellerCentral]: AmazonSellerCentral,
  [PLATFORMS.AmazonVendorCentral]: AmazonVendorCentral,
  [PLATFORMS.AmazonBrandRegistry]: AmazonBrandRegistry,
  [PLATFORMS.GoogleAds]: GoogleAds,
};

const PLATFORM_VALUES = {
  [PLATFORMS.AmazonSellerCentral]: 'SC',
  [PLATFORMS.AmazonVendorCentral]: 'VC',
  [PLATFORMS.AmazonBrandRegistry]: 'BR',
  [PLATFORMS.GoogleAds]: 'GA',
};

export function getPlatformLoginForm(platformId) {
  return {
    name: userStore.platformTypes.find(({ id }) => id === platformId)?.name,
    form: PLATFORM_MAPS[platformId] || GeneralPlatform,
  };
}

export function getPlatformName(platformId) {
  return userStore.platformTypes.find(({ id }) => id === platformId)?.name || 'None';
}

export function getPlatformValue(platformId) {
  return PLATFORM_VALUES[platformId] || 'NA';
}

export const PlatformLoginValidator = ({ Username, configuration }) =>
  Boolean(Username && configuration.Account && configuration.Marketplace);

export const GeneralLoginValidator = ({ Username }) => Boolean(Username);

const LOGIN_VALIDATORS = {
  [PLATFORMS.AmazonSellerCentral]: PlatformLoginValidator,
  [PLATFORMS.AmazonVendorCentral]: PlatformLoginValidator,
  [PLATFORMS.AmazonBrandRegistry]: PlatformLoginValidator,
  [PLATFORMS.GoogleAds]: GeneralLoginValidator,
};

const sortWorkflows = (workflowsList) => {
  const sortedWorkflows = orderBy(workflowsList, 'name', 'asc');
  return sortedWorkflows.map((category) => ({
    ...category,
    children: category.children?.length > 0 ? sortWorkflows(category.children) : [],
  }));
};

const makeFullTree = (workflowsList) => {
  const result = { children: [] };
  workflowsList.forEach((workflow) => {
    let currentCategory = result;
    workflow.path.forEach((name, index) => {
      const nextCategory = currentCategory.children.find((item) => item.name === name);
      if (!nextCategory) {
        const newCategory = { id: name, name, children: [], isExpanded: true };
        currentCategory.children.push(newCategory);
        currentCategory = newCategory;
      } else {
        currentCategory = nextCategory;
      }
      if (index === workflow.path.length - 1) currentCategory.children.push(workflow);
    });
  });
  return sortWorkflows(result.children);
};

export const getWorkflowConfigList = () => {
  const workflowConfigList = Object.entries(WORKFLOW_CONFIGS).map(([workflow, config]) => ({
    ...config,
    workflow,
    displayName: config.title,
    name: `${workflow}Config`,
    path: [config.category],
  }));
  const workflowTreeList = makeFullTree(workflowConfigList);
  return { workflowConfigList, workflowTreeList };
};

export function getValidPlatformLogin(platformLogin) {
  const getValidator = LOGIN_VALIDATORS[platformLogin.platform] || GeneralLoginValidator;
  return getValidator(platformLogin);
}

export const isEmptyConfig = (config) => !config || JSON.stringify(config) === '{}';

const isEmptyValue = (value) =>
  (value !== 0 && !value) || (typeof value === 'object' && isEmpty(value));

export const mapConfigFile = (file) =>
  isString(file) ? { id: `${file}`, name: file, hasWorkflowContent: true } : file;

export function getWorkflowFiles(workflowName, config) {
  if (workflowName && !isEmptyValue(config)) {
    if (workflowName.includes('Case')) {
      const filename = config.Create?.UpdatePdp?.Attachment;
      if (filename) return [mapConfigFile(filename)];
    }
    const attachments = WORKFLOW_CONFIGS[workflowName]?.attachments;
    return attachments?.reduce((acc, attachedNameField) => {
      const filename = config[attachedNameField];
      if (!filename) return acc;
      const fieldAttachment = Array.isArray(filename)
        ? filename.filter((name) => name).map(mapConfigFile)
        : [mapConfigFile(filename)];
      return acc.concat(fieldAttachment);
    }, []);
  }
  return null;
}

export function getWorkflowAttachments(workflowName, config) {
  if (workflowName && !isEmptyValue(config)) {
    if (workflowName.includes('Case')) {
      const filename = config.Create?.UpdatePdp?.Attachment;
      if (filename) return [mapConfigFile(filename)];
    }
    const attachments = WORKFLOW_CONFIGS[workflowName]?.attachments;
    return attachments?.reduce((acc, attachedNameField) => {
      const filename = config[attachedNameField];
      return {
        ...acc,
        [attachedNameField]: Array.isArray(filename)
          ? filename.filter((name) => name).map(mapConfigFile)
          : mapConfigFile(filename),
      };
    }, {});
  }
  return null;
}

export function getInitialCaseValue(config) {
  const platformType = config?.PlatformType ?? 0;
  const getCaseInfo = config?.[CASE_OPERATION_TYPES.get];
  const createCaseInfo = config?.[CASE_OPERATION_TYPES.create];
  const caseOperation = createCaseInfo ? CASE_OPERATION_TYPES.create : CASE_OPERATION_TYPES.get;
  const createType = createCaseInfo?.[CREATE_CASE_TYPES.updateProduct]
    ? CREATE_CASE_TYPES.updateProduct
    : createCaseInfo?.[CREATE_CASE_TYPES.updateVariation]
    ? CREATE_CASE_TYPES.updateVariation
    : CREATE_CASE_TYPES.removeVariation;

  const createCaseValue = createCaseInfo?.[createType] ?? {};

  const value = {
    CaseId: getCaseInfo?.CaseId ?? '',
    Business: createCaseValue?.Business ?? '',
    Description: createCaseValue?.Description ?? '',
    Asins: createCaseValue?.Asins ?? '',
    Subject: createCaseValue?.Subject ?? '',
    Attachment: createCaseValue?.Attachment ?? '',
  };

  return {
    platformType,
    caseOperation,
    createType,
    value,
  };
}
