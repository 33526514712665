import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import cx from 'clsx';

import { useStyles } from './sharedStyles';

export const CheckboxCell = ({ value, onChange }) => {
  const styles = useStyles();

  return (
    <TableCell
      key="checkbox"
      id="CheckboxCell"
      align="center"
      className={cx(styles.bodyCell, styles.pinCell)}
      onClick={onChange}
    >
      {value ? '✓' : ''}
    </TableCell>
  );
};

CheckboxCell.propTypes = {
  value: PropTypes.bool,
};

CheckboxCell.defaultProps = {
  value: false,
};
