import PropTypes from 'prop-types';
import cx from 'clsx';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@mui/styles';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '../Tooltip';

const useStyles = makeStyles((theme) => ({
  textFieldLabel: {
    fontFamily: 'Open Sans !important',
    fontWeight: '600 !important',
    fontSize: '14px !important',
    lineHeight: '19px !important',
    color: `${theme.palette.secondary.darkBlue} !important`,
    transform: 'none !important',
  },
  textFieldLabelDisabled: {
    color: `${theme.palette.neutrals.greyMed} !important`,
  },
  textFieldLabelError: {
    color: `${theme.palette.ui.error} !important`,
  },
  textFieldHelper: {
    fontFamily: 'Open Sans !important',
    fontWeight: '400 !important',
    fontSize: '12px !important',
    lineHeight: '19px !important',
    color: `${theme.palette.secondary.darkBlue} !important`,
    marginTop: '7px !important',
  },
  textFieldHelperEmpty: {
    marginTop: '0px !important',
  },
  textFieldHelperError: {
    color: `${theme.palette.ui.error} !important`,
  },
  smallLabel: {
    fontSize: '13px !important',
    fontWeight: '400 !important',
  },
  textFieldInput: {
    height: '46px',
    borderRadius: '58px',
    overflow: 'hidden',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflowWrap: 'break-word',
    marginTop: '25px !important',
    backgroundColor: theme.palette.neutrals.white,
    border: `1px solid ${theme.palette.neutrals.white}`,
    padding: '0px 24px',
    '&:before': {
      borderBottom: 'none !important',
    },
    '&:after': {
      borderBottom: 'none !important',
    },
  },
  smallSize: {
    height: '24px !important',
    borderRadius: '8px !important',
    marginTop: '20px !important',
  },
  compactSpace: {
    padding: '0px 8px !important',
  },
  textFieldInputHiddenLabel: {
    marginTop: '0px !important',
  },
  textFieldBaseInput: {
    fontFamily: 'Open Sans !important',
    fontSize: '16px !important',
    fontWeight: '400 !important',
    lineHeight: '150% !important',
    letterSpacing: '0.15px !important',
    color: `${theme.palette.secondary.darkBlue} !important`,
  },
  textFieldSmallInput: {
    fontSize: '14px !important',
    padding: '0px !important',
  },
  textFieldInputPassword: {
    textSecurity: 'circle' /* IE/Safari */,
    '-moz-text-security': 'circle' /* FireFox */,
    '-webkit-text-security': 'circle' /* Chrome/Safari  */,
  },
  textFieldInputFocus: {
    border: `1px solid ${theme.palette.primary.purple}`,
  },
  textFieldInputSearch: {
    marginTop: 0,
    padding: '0px 32px 0px 8px',
  },
  textFieldDisabled: {
    backgroundColor: `#F8F9FE  !important`,
    border: `1px solid #F8F9FE`,
    '-webkit-text-fill-color': `${theme.palette.neutrals.grayBlueDark} !important`,
  },
  textFieldDark: {
    backgroundColor: theme.palette.neutrals.grayBlue,
    border: `1px solid ${theme.palette.neutrals.grayBlue}`,
  },
  textFieldError: {
    border: `1px solid ${theme.palette.ui.error}`,
    '&:focus': {
      border: `1px solid ${theme.palette.ui.error}`,
    },
  },
  multiline: {
    padding: '0px 24px',
  },
  tooltip: {
    maxWidth: '170px !important',
  },
  tooltipIcon: {
    paddingLeft: 8,
    fontSize: '13px !important',
  },
}));

const TextField = (props) => {
  const {
    variant,
    type,
    isDarkTheme,
    label,
    tooltipText,
    error,
    disabled,
    placeholder,
    helperText,
    endAdornment,
    multiline,
    classes,
    id: dataTestId,
    size,
    value,
    inputClassName = '',
    ...rest
  } = props;
  const styles = useStyles();
  const isSmallSize = size === 'small';
  const isSearchField = variant === 'search';

  return (
    <FormControl
      id={dataTestId}
      {...rest}
      variant="standard"
      error={error}
      disabled={disabled}
      classes={{ root: classes?.container }}
      style={{ flex: classes?.flex }}
    >
      {variant !== 'search' && label && (
        <InputLabel
          shrink
          htmlFor="component-textfield"
          classes={{
            root: cx(styles.textFieldLabel, {
              [styles.smallLabel]: isSmallSize,
              [styles.textFieldLabelError]: error,
              [styles.textFieldLabelDisabled]: disabled,
            }),
          }}
        >
          <Tooltip placement="right" arrow title={tooltipText} className={styles.tooltip}>
            <div>
              {label}
              {tooltipText && <InfoIcon className={styles.tooltipIcon} />}
            </div>
          </Tooltip>
        </InputLabel>
      )}
      <Input
        className={inputClassName}
        {...rest}
        error={error}
        aria-describedby="component-textfield-text"
        placeholder={placeholder}
        endAdornment={endAdornment}
        type={type}
        value={value}
        multiline={multiline}
        disabled={disabled}
        classes={{
          root: cx(
            styles.textFieldInput,
            {
              [styles.smallSize]: isSmallSize,
              [styles.compactSpace]: isSmallSize && !isSearchField,
              [styles.textFieldInputSearch]: isSearchField,
              [styles.textFieldDark]: isDarkTheme,
              [styles.textFieldInputHiddenLabel]: !label || isSearchField,
            },
            classes?.root,
          ),
          input: cx(styles.textFieldBaseInput, {
            [styles.textFieldInputPassword]: type === 'password',
            [styles.textFieldSmallInput]: isSmallSize,
          }),
          focused: styles.textFieldInputFocus,
          error: styles.textFieldError,
          disabled: styles.textFieldDisabled,
          multiline: multiline && styles.multiline,
        }}
      />
      {!!helperText && (
        <FormHelperText
          id="component-textfield-text"
          classes={{
            root: cx(styles.textFieldHelper, {
              [styles.textFieldHelperError]: error,
              [styles.textFieldHelperEmpty]: !helperText,
            }),
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

TextField.propTypes = {
  variant: PropTypes.oneOf(['text', 'search']),
  isDarkTheme: PropTypes.bool,
  label: PropTypes.any,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  endAdornment: PropTypes.node,
  multiline: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'default']),
};

TextField.defaultProps = {
  variant: 'text',
  isDarkTheme: false,
  label: null,
  error: false,
  disabled: false,
  placeholder: '',
  helperText: '',
  endAdornment: null,
  multiline: false,
  size: 'small',
  value: '',
};

export default TextField;
