import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormGroup, Popover } from '@mui/material';
import Typography from '../Typography';
import { useStyles } from './styles';
import Radio from '../Radio';

const PAGE_NAME = 'MultiRadioDropdown';

const MultiRadioDropdown = ({
  value: values,
  placeholder,
  options,
  onChange,
  defaultValue,
  classes,
  anchorOrigin,
  transformOrigin,
  ...rest
}) => {
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const currentState = useMemo(() => {
    const currentValue = !values?.length ? defaultValue : values[0];
    return options.find((item) => item.value === currentValue);
  }, [values, defaultValue, options]);

  const handleOpen = (target) => {
    setAnchorEl(target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (value) => {
    onChange(value);
  };

  const handleDropDownKeyDownProcess = (event) => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      event.stopPropagation();
      handleOpen(event.currentTarget);
    }
  };

  return (
    <>
      <div
        id={rest.id}
        tabIndex={0}
        role="button"
        onKeyDown={handleDropDownKeyDownProcess}
        className={cx(
          styles.container,
          {
            [styles.selected]: Boolean(anchorEl),
            [styles.selectedContainer]: values.length > 0,
          },
          classes?.root,
        )}
        onClick={(event) => {
          handleOpen(event.currentTarget);
        }}
      >
        <Typography
          id={`${PAGE_NAME}_selectedValue`}
          variant="body2"
          style={{ whiteSpace: 'nowrap', minWidth: '60px' }}
        >
          {currentState.label}
        </Typography>
        <div className={cx(styles.moreIcon, { [styles.open]: Boolean(anchorEl) })}>
          <ExpandMoreIcon />
        </div>
      </div>

      <Popover
        anchorEl={anchorEl}
        id={`${PAGE_NAME}_Popover`}
        open={Boolean(anchorEl)}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={handleClose}
        classes={{ paper: cx(styles.paper, classes?.paper) }}
      >
        <div className={styles.popoverContent}>
          <Typography className={styles.title}>{placeholder}</Typography>
          <div className={styles.menuContainer}>
            <FormGroup style={{ flexDirection: 'column' }}>
              {options.map(({ value, label }) => (
                <Radio
                  key={label}
                  size="small"
                  label={label}
                  value={value}
                  checked={value === currentState.value}
                  onChange={(e) => handleChange([e.target.value])}
                />
              ))}
            </FormGroup>
          </div>
        </div>
      </Popover>
    </>
  );
};

MultiRadioDropdown.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.any, label: PropTypes.string })),
  placeholderColor: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  transformOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
};

MultiRadioDropdown.defaultProps = {
  value: [],
  placeholder: '',
  options: [],
  placeholderColor: '',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};

export default MultiRadioDropdown;
