import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useMainStore } from '@/pages/main/stores/useMainStore';
import { ServiceTypes } from '@/utils/types';
import { withStore } from '@/store';

const SpecificForm = ({ workflowInfo, showError, showWarning }) => {
  const [SpecifiedForm, setSpecifiedForm] = useState(null);
  const { workflowStore } = useMainStore();
  useEffect(() => {
    const staticFormName = workflowInfo?.workflow;
    if (staticFormName) {
      import(`../SpecificForms/${staticFormName}`)
        .then((module) => {
          setSpecifiedForm(() => module[staticFormName]);
        })
        .catch(() => {
          workflowStore.setServiceType(ServiceTypes.single);
          workflowStore.updateStaticFormValue(null, true);
          setSpecifiedForm(null);
        });
    }
  }, [workflowInfo?.workflow]);

  if (!SpecifiedForm) {
    return <></>;
  }

  return <SpecifiedForm showError={showError} showWarning={showWarning} />;
};

export default withStore(observer(SpecificForm));
