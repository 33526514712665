import { Config } from '@/config';
import * as fetchWithAuth from './fetchWithAuth';

function parseEventTypesFromResponse(eventName) {
  return { label: eventName, value: eventName };
}

export const getEventTypes = async () => {
  return await fetchWithAuth
    .get(`${Config.NEW_API}/Picklist/events`)
    .then((resp) => resp.map(parseEventTypesFromResponse));
};
