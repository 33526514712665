import SettingsMenuItem from './SettingsMenuItem';
import { useStyles } from './styles';

const PAGE_NAME = 'SettingsMenu';

const SettingsMenu = () => {
  const styles = useStyles();

  return (
    <div className={styles.sideContainer}>
      <div className={styles.sideContent}>
        <div id={`${PAGE_NAME}_settings`} className={styles.topWrapper}>
          <span id={`${PAGE_NAME}_title`} className={styles.title}>
            Settings
          </span>
          <div className={styles.topDivider} />
        </div>
        <div id={`${PAGE_NAME}_group`} className={styles.body}>
          <SettingsMenuItem isSelected label="My Profile" />
        </div>
      </div>
    </div>
  );
};

export default SettingsMenu;
