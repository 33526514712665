import { useState } from 'react';
import cx from 'clsx';
import { IconButton, Stack } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles } from '@mui/styles';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';

import TextField from '../TextField';
import InfoRow from '../InfoRow';
import { CopyOutlineIcon } from '@/assets/icons';

const useStyles = makeStyles((theme) => ({
  searchField: {
    position: 'absolute',
    right: '0px',
    marginTop: '-1px',
    color: `${theme.palette.secondary.darkBlue} !important`,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const PasswordField = ({
  label,
  defaultValue,
  disabled,
  onChange,
  setShowCode,
  onCopyClick,
  ...rest
}) => {
  const styles = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prev) => !prev);
    if (setShowCode) setShowCode((prev) => !prev);
  };

  return (
    <Stack spacing={1} direction="row" alignItems="center">
      <InfoRow label={label} fullWidth>
        <TextField
          {...rest}
          type={showPassword ? '' : 'password'}
          onChange={onChange}
          disabled={disabled}
          endAdornment={
            <InputAdornment
              position="start"
              classes={{
                root: cx(styles.searchField),
              }}
            >
              {showPassword ? (
                <VisibilityOffOutlined fontSize="small" onClick={handleTogglePassword} />
              ) : (
                <VisibilityOutlined fontSize="small" onClick={handleTogglePassword} />
              )}
            </InputAdornment>
          }
        />
      </InfoRow>
      <IconButton size="small" onClick={onCopyClick}>
        <CopyOutlineIcon fontSize="small" />
      </IconButton>
    </Stack>
  );
};

export default PasswordField;
