import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { orderBy } from 'lodash';
import { organizationApi, userApi } from '../api';
import { showErrorNotification, showSuccessNotification, sortMembers } from '../utils';
import { setError } from '../utils/errors';
import { userStore } from './UserStore';
import { FilterType } from '../utils/constants';
import {
  InitUserFilters,
  MemberSearchKeys,
  MemberStatusList,
  USER_COLUMN_KEYS,
} from '../utils/users';
import { BASE_ROUTES, TRISTATE, NotificationText } from '@/utils/types';
import { accountStore } from './AccountStore';

export class MembersStore {
  parentOrgId = null;
  organization = null;
  accounts = [];
  allAccounts = [];
  arrangedAccounts = [];
  members = [];
  pageRows = 1000;
  currentPage = 1;
  currentSort = { name: 'name', direction: 'asc' };
  selectedAccount = {};
  isLoading = true;

  filters = InitUserFilters;
  filterSearch = '';
  search = '';
  selectedUserIds = [];
  selectedUsers = [];
  unionInfo = {};
  accessInfo = {};

  constructor() {
    makeObservable(this, {
      organization: observable,
      accounts: observable,
      arrangedAccounts: observable,
      allAccounts: observable,

      parentOrgId: observable,
      selectOrganizationId: action,

      members: observable,
      allFilteredMembers: computed,
      filteredCurrentPageMembers: computed,

      pageRows: observable,
      setPageRows: action,

      paginationCounts: computed,
      currentPage: observable,
      setPageNumber: action,

      isLoading: observable,
      setIsLoading: action,

      currentSort: observable,
      filters: observable,
      filteredResult: computed,
      allFilteredCount: computed,

      selectedUsers: observable,
      selectedUserIds: observable,
      updateSelectedUser: action,
      setSelectedUser: action,
      selectedIds: computed,
      refreshSelectedUsers: action,

      accessInfo: observable,
      setAccessInfo: action,

      unionInfo: observable,
      resetUnionInfo: action,
      updateUnionInfo: action,

      setMembers: action,

      filterSearch: observable,
      setFilterSearch: action,

      search: observable,
      setSearch: action,
      clearFilter: action,
      isSearchedResultEmpty: computed,
      onToggleExpand: action,
      selectedAccount: observable,
      onSelectAccount: action,

      updateFilterValues: action,
    });
  }

  isActiveSort(sortName) {
    return this.currentSort.name === sortName;
  }

  getSortDirection(sortName) {
    if (this.currentSort.name === sortName) return this.currentSort.direction;
    return 'asc';
  }

  setSortColumn(name, direction) {
    this.currentSort = { name, direction };
  }

  toggleSort(name) {
    let direction = 'asc';
    if (this.currentSort.name === name) {
      direction = this.currentSort.direction === 'asc' ? 'desc' : 'asc';
    } else {
      direction = 'desc';
    }
    this.currentSort = { name, direction };
  }

  get paginationCounts() {
    return Math.ceil(this.allFilteredMembers.length / this.pageRows);
  }

  setPageNumber(value) {
    this.currentPage = value;
  }

  setPageRows(value) {
    this.pageRows = value;
    if (this.currentPage > this.paginationCounts) this.setPageNumber(1);
  }

  selectOrganizationId(id) {
    this.parentOrgId = id;
  }

  async createSaveUser(user, warningTitle) {
    this.setIsLoading(!user.id);
    let result = null;
    try {
      await userApi.saveUser(user);
      if (userStore.currentUser.id === user.id) {
        userStore.setUser({ ...userStore.currentUser, ...user });
      }
      if (user.id) {
        this.members = this.members.map((member) => (member.id === user.id ? user : member));
        result = user;
      } else {
        await this.fetchMembers();
        result = this.members.find(({ email }) => email === user.email);
      }
      showSuccessNotification('User successfully saved!');
    } catch (err) {
      setError(err, false, warningTitle);
      showErrorNotification(`${warningTitle}\r\n${err.message}\r\n${err.preview}`);
    }
    this.setIsLoading(false);
    return result;
  }

  async removeUser(userId, email, helpItemCount, reassignMemberId) {
    this.setIsLoading(true);
    try {
      if (helpItemCount > 0) {
        await userApi.reassignTasks(userId, reassignMemberId);
      }
      const userInfo = await userApi.removeUser(userId, email);
      await this.fetchMembers();
      return userInfo;
    } catch (err) {
      setError(err, false, NotificationText.removeUserError);
    }
    this.setIsLoading(false);
    return null;
  }

  getNewUser(user) {
    return {
      ...user,
      isActive: this.unionInfo.isActive ?? user.isActive,
      isAdmin: this.unionInfo.isAdmin ?? user.isAdmin,
      isAssignable: this.unionInfo.isAssignable ?? user.isAssignable,
      isContributor: this.unionInfo.isContributor ?? user.isContributor,
      profile: {
        ...user.profile,
        emailsEnabled: this.unionInfo.emailsEnabled ?? user.profile?.emailsEnabled,
      },
    };
  }

  async saveSelectedUsers() {
    this.setIsLoading(true);
    try {
      await Promise.all(
        this.selectedUsers.map(async (user) => {
          const newUser = this.getNewUser(user);
          await userApi.saveUser(newUser);
        }),
      );
      this.members = this.members.map((member) =>
        this.selectedIds.includes(member.id) ? this.getNewUser(member) : member,
      );
      this.refreshSelectedUsers();
      showSuccessNotification('Users successfully saved!');
    } catch (err) {
      setError(err, false, 'Save failed');
      showErrorNotification(`Save failed!\r\n${err.message}\r\n${err.preview}`);
    }
    this.setIsLoading(false);
  }

  get allFilteredMembers() {
    const filter = this.filteredResult;
    const selectedOrgId = accountStore.selectedAccount?.id;
    const filteredMembers = this.members.filter(
      (member) =>
        (!selectedOrgId || member.organizationPath?.includes(selectedOrgId)) &&
        Object.keys(filter).every((key) => {
          if (key === FilterType.viewBy || filter[key]?.selectedValues?.length === 0) return true;
          if (key === FilterType.status) {
            return filter[key].selectedValues.every((checked, index) => {
              const value = member[key].includes(MemberStatusList[index].label);
              return (
                checked === null ||
                checked === TRISTATE.unchecked ||
                (checked === TRISTATE.checked && value) ||
                (checked === TRISTATE.indeterminate && !value)
              );
            });
          }
          return filter[key].selectedValues.includes(member[key]);
        }),
    );

    const orderedMembers = orderBy(
      filteredMembers,
      [(member) => member[this.currentSort.name]?.toString().toLowerCase(), 'date'],
      [this.currentSort.direction, 'desc'],
    );

    return orderedMembers.reduce((result, member) => {
      let match, matchedField;
      if (
        MemberSearchKeys.some((key) => {
          if (this.search.length === 0) return true;
          if (!member[key]) return false;
          // get match by search filter
          const startIndex = `${member[key]}`.toLowerCase().indexOf(this.search.toLowerCase());
          if (startIndex === -1) return false;
          match = [startIndex, startIndex + this.search.length];
          matchedField = key;
          return true;
        })
      ) {
        if (matchedField) return [...result, { ...member, match, matchedField }];
        return [...result, member];
      }
      return result;
    }, []);
  }

  get filteredCurrentPageMembers() {
    return this.allFilteredMembers.slice(
      (this.currentPage - 1) * this.pageRows,
      this.currentPage * this.pageRows,
    );
  }

  setSelectedUser(user) {
    this.members = this.members.map((member) => {
      const currentValue = member[USER_COLUMN_KEYS.select];
      const newValue = member.id === user?.id;
      return currentValue !== newValue && (newValue || currentValue)
        ? { ...member, [USER_COLUMN_KEYS.select]: newValue }
        : member;
    });
    this.selectedUsers = user ? [{ ...user, [USER_COLUMN_KEYS.select]: true }] : [];
    this.resetUnionInfo();
  }

  get selectedIds() {
    return this.selectedUsers.map(({ id }) => id);
  }

  get firstSelectedUser() {
    return this.selectedUsers?.[0];
  }

  refreshSelectedUsers() {
    this.selectedUsers = this.filteredCurrentPageMembers.filter(
      ({ [USER_COLUMN_KEYS.select]: isSelected }) => isSelected,
    );
    this.resetUnionInfo();
  }

  resetUnionInfo() {
    this.selectedUserIds = this.selectedUsers.map(({ id }) => id);
    if (this.selectedUsers.length === 1)
      this.unionInfo = {
        ...this.firstSelectedUser,
        emailsEnabled: this.firstSelectedUser.profile?.emailsEnabled,
      };
    else
      this.unionInfo = {
        isActive: null,
        isAdmin: null,
        isAssignable: null,
        isContributor: null,
        emailsEnabled: null,
      };
  }

  updateSelectedUser(fieldValue) {
    if (this.selectedUsers.length === 1) {
      this.selectedUsers = [{ ...this.firstSelectedUser, ...fieldValue }];
      this.resetUnionInfo();
    }
  }

  setAccessInfo(value) {
    this.accessInfo = value;
  }

  updateUnionInfo(fieldValue) {
    this.unionInfo = { ...this.unionInfo, ...fieldValue };
  }

  get isSelectedAll() {
    const selectedCount = this.selectedUsers.length;
    return selectedCount === this.filteredCurrentPageMembers.length;
  }

  get isIndeterminate() {
    return !this.isSelectedAll && this.selectedUsers.length > 0;
  }

  onToggleSelectUser(id) {
    this.members = this.members.map((member) =>
      member.id === id
        ? { ...member, [USER_COLUMN_KEYS.select]: !member[USER_COLUMN_KEYS.select] }
        : member,
    );
    this.refreshSelectedUsers();
  }

  selectUsers(newSelection) {
    this.members = this.members.map((member) => ({
      ...member,
      [USER_COLUMN_KEYS.select]: newSelection.includes(member.id),
    }));
    this.refreshSelectedUsers();
  }

  onSelectUser(id) {
    let newUser = null;
    this.members = this.members.map((member) => {
      const currentValue = member[USER_COLUMN_KEYS.select];
      const isSelected = member.id === id;
      const user = { ...member, [USER_COLUMN_KEYS.select]: isSelected };
      if (isSelected) newUser = user;
      return currentValue !== isSelected && (isSelected || currentValue) ? user : member;
    });
    this.selectedUsers = newUser ? [newUser] : [];
    this.resetUnionInfo();
  }

  onSelectAllUser(value) {
    this.members = this.members.map((member) => ({
      ...member,
      [USER_COLUMN_KEYS.select]: value,
    }));
    this.refreshSelectedUsers();
  }

  hasColumn(key) {
    return (
      key === USER_COLUMN_KEYS.select ||
      this.filters[FilterType.viewBy].selectedValues.includes(key)
    );
  }

  getOrganizationName(accounts, orgId) {
    return accounts.reduce((res, account) => {
      if (account.id === orgId) {
        return account.name;
      }
      if (account.children.length > 0) {
        const name = this.getOrganizationName(account.children, orgId);
        return name || res;
      }
      return res;
    }, '');
  }

  async fetchMembers() {
    if (!this.allAccounts.length) return;
    this.setIsLoading(true);
    try {
      const users = await organizationApi.getOrganizationUsers(userStore.organizationId);
      runInAction(() => {
        const members = sortMembers(users).map((user) => ({
          ...user,
          organization: this.getOrganizationName(this.allAccounts, user.organizationId),
        }));
        this.setMembers(members);
      });
    } catch (err) {
      setError(err);
    }
    this.setIsLoading(false);
  }

  async fetchAllAccounts(result = null) {
    const organizationId = userStore.organizationId;
    if (!organizationId) return;
    this.setIsLoading(true);
    try {
      const {
        parent: organization,
        children: accounts,
        arrangedAccounts,
      } = result ?? (await organizationApi.getOrganizationFullTree(organizationId));
      this.organization = organization;
      this.allAccounts = [this.organization, ...accounts];
      this.accounts = accounts;
      this.arrangedAccounts = arrangedAccounts;
      if (this.accounts.length > 0) {
        this.selectOrganizationId(this.accounts?.[0].id);
      }
    } catch (err) {
      setError(err);
    }
    this.setIsLoading(false);
  }

  async refreshAll() {
    const organizationId = userStore.organizationId;
    if (!organizationId) return;
    this.setIsLoading(true);
    try {
      await this.fetchAllAccounts();
      await this.fetchMembers();
    } catch (err) {
      setError(err);
    }
    this.setIsLoading(false);
  }

  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  //NOTE: Fetch filter list from members
  setMembers(members) {
    this.members = members;
  }

  get filteredResult() {
    const filteredResult = Object.keys(this.filters).reduce((result, filterKey) => {
      const newValues = [];
      this.filters[filterKey].values.forEach((item) => {
        // get match by search filter
        const startIndex = item.value?.toLowerCase().indexOf(this.filterSearch.toLowerCase());
        if (startIndex !== -1) {
          newValues.push({
            ...item,
            match: [startIndex, startIndex + this.filterSearch.length],
          });
        }
      });

      return {
        ...result,
        [filterKey]: { ...this.filters[filterKey], values: newValues },
      };
    }, {});

    return filteredResult;
  }

  selectAll() {
    this.setFilterSearch('');
    this.filters = Object.keys(this.filters).reduce(
      (result, key) => ({
        ...result,
        [key]: { ...this.filters[key], selectedValues: [] },
      }),
      {},
    );
  }

  updateFilterValues(key, value) {
    this.filters[key].selectedValues = value;
    if (key === FilterType.viewBy) {
      userStore.updatePageSetting(BASE_ROUTES.users, {
        columns: value,
      });
    }
  }

  setFilterSearch(value) {
    this.filterSearch = value;
  }

  setSearch(value) {
    this.search = value?.trim();
  }

  get isSearchedResultEmpty() {
    return Object.keys(this.filteredResult).every(
      (key) => this.filteredResult[key].values.length === 0,
    );
  }

  clearFilter() {
    this.setSearch('');
    this.filters = InitUserFilters;
  }

  getSelectedCount(type) {
    return this.filteredResult[type]?.selectedValues?.length || 0;
  }

  get allFilteredCount() {
    const count = Object.keys(this.filteredResult).reduce(
      (acc, key) => acc + this.getSelectedCount(key),
      0,
    );
    return count;
  }

  toggleExpandMore(accounts, selectedId) {
    for (let i = 0; i < accounts.length; i++) {
      const account = accounts[i];
      if (account.id === selectedId) {
        accounts[i] = {
          ...accounts[i],
          isExpanded: account.isExpanded ? false : true,
        };
        return;
      }
      if (account.children.length > 0) {
        const result = this.toggleExpandMore(account.children, selectedId);
        if (result) return;
      }
    }
  }

  onToggleExpand(accountId) {
    this.toggleExpandMore(this.accounts, accountId);
  }

  resetAccounts(accounts) {
    return accounts.map((account) => {
      return {
        ...account,
        isExpanded: false,
        children: account.children.length > 0 ? this.resetAccounts(account.children) : [],
      };
    });
  }

  updateAccounts(accountId) {
    const selectedAccount = this.getAccountFromId(this.allAccounts, accountId);
    if (!selectedAccount) return;
    this.accounts = this.resetAccounts(this.accounts);

    let accounts = this.accounts;
    selectedAccount.path.forEach((orgId) => {
      const index = accounts.findIndex((item) => item.id === orgId);
      if (index !== -1) {
        accounts[index] = { ...accounts[index], isExpanded: true };
        accounts = accounts[index].children;
      }
    });
  }

  getAccountFromId(accounts, selectedId) {
    for (let i = 0; i < accounts.length; i++) {
      const account = accounts[i];
      if (account.id === selectedId) return account;
      if (account.children.length > 0) {
        const result = this.getAccountFromId(account.children, selectedId);
        if (result) return result;
      }
    }
    return null;
  }

  onSelectAccount(accountId) {
    if (!this.allAccounts?.length) return;
    this.selectedAccount = this.getAccountFromId(this.allAccounts, accountId);
  }

  updateAllSelectedValues(value) {
    if (value[FilterType.status]?.length >= InitUserFilters[FilterType.status].values.length) {
      this.filters[FilterType.status].selectedValues = value[FilterType.status];
    }
  }

  updateFilterSelectedValues(value) {
    Object.keys(value).forEach((filterKey) => {
      if (this.filters[filterKey]) {
        this.filters[filterKey].selectedValues = value[filterKey].selectedValues;
      }
    });
  }

  dispose() {
    // TBD
  }
}
