import { IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AutomationStatusIcons } from '@/utils/types';
import { TextLink, Typography } from '..';
import clsx from 'clsx';
import { CopyOutlineIcon } from '@/assets/icons';

const useStyles = makeStyles(({ palette }) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tooltipContainer: {
    padding: '8px 13px 8px 8px !important',
    borderRadius: '12px !important',
    background: 'white !important',
    border: '1px solid #DDD',
    boxShadow: '0px 10px 10px 0px rgba(0, 0, 0, 0.10)',
  },
  rowWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
    height: '100%',
  },
  linkIcon: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: '18px',
    lineHeight: '20px',
    color: ({ statusColor }) => `${statusColor} !important`,
  },
  smallIcon: {
    fontSize: '16px',
    lineHeight: '16px',
  },
  tooltipWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  name: {
    fontWeight: 700,
    color: palette.neutrals.grey,
  },
  description: {
    color: palette.neutrals.greyBlueDark,
  },
  buildError: {
    flex: 1,
    overflowWrap: 'anywhere',
    overflow: 'auto',
    maxHeight: '200px',
  },
}));

const AutomationTooltip = ({ automation }) => {
  const classes = useStyles();
  const { name, automationTime, buildError } = automation;
  const handleCopyBuildError = () => {
    navigator.clipboard.writeText(buildError);
  };

  return (
    <div className={classes.tooltipWrapper}>
      <Typography variant="subtitle2" className={classes.name}>
        {name}
      </Typography>
      <Typography variant="subtitle2" className={classes.description}>
        {automationTime}
      </Typography>
      {buildError && (
        <>
          <div className={classes.header}>
            <Typography variant="subtitle2">Build Error</Typography>
            <IconButton id="copyButton" size="small" onClick={handleCopyBuildError}>
              <CopyOutlineIcon fontSize="small" />
            </IconButton>
          </div>
          <Typography variant="body2" className={classes.buildError}>
            {buildError}
          </Typography>
        </>
      )}
    </div>
  );
};

export const AutomationLog = ({ automation, isAdminUser, smallSize }) => {
  const classes = useStyles({ statusColor: automation?.color });
  if (!automation) return null;
  const { name, buildId, link } = automation;

  return (
    <Tooltip
      title={<AutomationTooltip automation={automation} />}
      classes={{ tooltip: classes.tooltipContainer }}
    >
      <div className={classes.rowWrapper}>
        <TextLink
          href={isAdminUser && buildId && link}
          className={clsx(classes.linkIcon, { [classes.smallIcon]: smallSize })}
          onClick={(e) => e.stopPropagation()}
        >
          {AutomationStatusIcons?.[name] ?? AutomationStatusIcons.none}
        </TextLink>
      </div>
    </Tooltip>
  );
};
