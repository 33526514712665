import { useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'clsx';
import { InputLabel, Popover, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';

import { getExpandedChildrenCount } from '@/utils';
import { AnchorOrigin } from '@/utils/types';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@/components';
import { useSettingsStore } from '@/store';
import { DEFAULT_ITEM_HEIGHT } from '@/utils/constants';
import TreeSubItem from './TreeSubItem';

import styles from './OrgAccessDropdown.module.css';

const PAGE_NAME = 'OrgAccessDropdown';

export const OrgAccessDropdown = observer(
  ({
    variant = 'small',
    anchorPosition = AnchorOrigin.auto,
    label,
    value,
    width = 454,
    disabled = false,
    onChange,
    className,
  }) => {
    const classes = makeStyles({ paper: { width } })();
    const { membersStore } = useSettingsStore();
    const dropdownRef = useRef();
    const listContainerRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [search, setSearch] = useState('');
    const [hoverAccountId, setHoverAccountId] = useState(value);
    const selectedAccountName = membersStore.getAccountFromId(
      membersStore.allAccounts,
      value,
    )?.name;
    const popoverPosition =
      anchorPosition === AnchorOrigin.bottom ||
      (anchorPosition === AnchorOrigin.auto &&
        dropdownRef?.current?.getBoundingClientRect().y < window.innerHeight - 356)
        ? { anchorOrigin: 'bottom', transformOrigin: 'top', marginTop: '6px' }
        : { anchorOrigin: 'top', transformOrigin: 'bottom', marginTop: '-6px' };

    useEffect(() => {
      if (Boolean(anchorEl)) {
        let count = getExpandedChildrenCount(membersStore.accounts, value);
        const timeout = setTimeout(() => {
          listContainerRef.current?.scrollTo({
            top: (count - 1) * DEFAULT_ITEM_HEIGHT,
            behavior: 'smooth',
          });
        }, 500);
        return () => clearTimeout(timeout);
      } else {
        setSearch('');
        setHoverAccountId(value);
      }
    }, [anchorEl, value]);

    const handleClose = () => {
      setAnchorEl(null);
      setSearch('');
    };

    const handleSelectAccount = (org) => {
      setAnchorEl(null);
      onChange(org.id, org.pathByName);
    };

    const filteredAccountList = useMemo(() => {
      if (search) {
        const getAccountList = (accounts) => {
          return accounts.reduce((res, account) => {
            const startIndex = account.name.toLowerCase().indexOf(search.toLowerCase().trim());
            const childList = getAccountList(account.children);
            if (startIndex === -1) return [...res, ...childList];
            return [
              ...res,
              {
                ...account,
                children: [],
                match: [startIndex, startIndex + search.length],
              },
              ...childList,
            ];
          }, []);
        };

        return getAccountList(membersStore.accounts);
      }
      return membersStore.accounts;
    }, [search, membersStore.accounts]);

    const handleKeyDownProcess = (event) => {
      if (!search) return;
      let selectedIndex = filteredAccountList.findIndex((account) => account.id === hoverAccountId);

      const optionCount = filteredAccountList?.length;
      if (optionCount) {
        let isChangeSelectedIndex = false;
        if (event.keyCode === 40) {
          event.stopPropagation();
          selectedIndex = (selectedIndex + 1) % optionCount;
          isChangeSelectedIndex = true;
        } else if (event.keyCode === 38) {
          event.stopPropagation();
          selectedIndex = selectedIndex - 1;
          if (selectedIndex < 0) selectedIndex = optionCount - 1;
          isChangeSelectedIndex = true;
        } else if (event.keyCode === 13) {
          event.stopPropagation();
          onChange(hoverAccountId);
          handleClose();
        }
        if (isChangeSelectedIndex) {
          listContainerRef.current.scrollTo(
            0,
            selectedIndex === optionCount - 1
              ? listContainerRef.current.scrollHeight
              : ((listContainerRef.current.scrollHeight - listContainerRef.current.offsetHeight) *
                  selectedIndex) /
                  optionCount,
          );
          setHoverAccountId(filteredAccountList[selectedIndex].id);
        }
      }
    };

    const renderSubAccountItem = (account, layer) => {
      return (
        <Accordion
          key={account.id}
          classes={{
            accordionRoot: styles.accordionRoot,
            accordionExpanded: styles.accordionExpanded,
          }}
          sx={{
            '&:before': {
              display: layer !== 0 && 'none',
            },
          }}
          id={`${PAGE_NAME}_accounts_accordion`}
          expanded={!!account.isExpanded}
        >
          <AccordionSummary
            id={`${PAGE_NAME}_accounts_accordion_content`}
            containerClassName={cx({
              [styles.accordionSummary]: 1,
              [styles.accordionSelectedSummary]: hoverAccountId === account.id,
            })}
            contentClassName={styles.accordionSummaryContent}
          >
            <TreeSubItem
              key={account.id}
              isChild={account.children?.length > 0}
              isExpanded={!!account.isExpanded}
              isSelected={value === account.id}
              isHover={hoverAccountId === account.id}
              label={account.name}
              match={account.match}
              onSelect={(e) => {
                e.stopPropagation();
                handleSelectAccount(account);
              }}
              onToggleExpand={(e) => {
                e.stopPropagation();
                membersStore.onToggleExpand(account.id);
              }}
            />
          </AccordionSummary>
          {account.children?.length > 0 && (
            <AccordionDetails className={cx(styles.accordionDetails)}>
              {account.children.map((subAccount) => renderSubAccountItem(subAccount, layer + 1))}
            </AccordionDetails>
          )}
        </Accordion>
      );
    };

    const handleDropdownClick = (event) => {
      if (!disabled && membersStore.accounts.length > 0) {
        membersStore.updateAccounts(value);
        setAnchorEl(event.currentTarget);
      }
    };

    return (
      <>
        {!!label && (
          <InputLabel
            shrink
            htmlFor="component-selection-label"
            classes={{
              root: styles.selectLabel,
            }}
          >
            {label}
          </InputLabel>
        )}
        {variant === 'default' && (
          <div
            ref={dropdownRef}
            className={cx(
              styles.accessDropdown,
              styles.defaultDropdown,
              {
                [styles.selected]: Boolean(anchorEl),
                [styles.disabled]: disabled || membersStore.accounts.length === 0,
              },
              className,
            )}
            onClick={handleDropdownClick}
          >
            <Typography
              variant="body1"
              className={cx(styles.truncatedText, {
                [styles.selectLabelDisabled]: disabled,
              })}
            >
              {selectedAccountName}
            </Typography>
            <div
              className={cx(styles.moreDefaultIcon, {
                [styles.moreDefaultRotate]: Boolean(anchorEl),
              })}
            >
              <ExpandMoreIcon
                className={cx(styles.moreDefaultSvg, {
                  [styles.selectLabelDisabled]: disabled,
                })}
              />
            </div>
          </div>
        )}

        {variant === 'small' && (
          <div ref={dropdownRef} className={styles.accessDropdown} onClick={handleDropdownClick}>
            <Typography
              variant="body2"
              className={cx(styles.truncatedText, {
                [styles.selectLabelDisabled]: disabled,
              })}
            >
              {selectedAccountName}
            </Typography>
            <div
              className={cx(styles.moreIcon, {
                [styles.moreRotate]: Boolean(anchorEl),
              })}
            >
              <ExpandMoreIcon className={styles.moreSvgIcon} />
            </div>
          </div>
        )}

        <Popover
          anchorEl={anchorEl}
          id={`${PAGE_NAME}_Popover`}
          open={Boolean(anchorEl)}
          anchorOrigin={{
            vertical: popoverPosition.anchorOrigin,
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: popoverPosition.transformOrigin,
            horizontal: 'center',
          }}
          onClose={handleClose}
          classes={{ paper: cx(styles.paper, classes.paper) }}
          sx={{ marginTop: popoverPosition.marginTop }}
        >
          <div className={styles.popoverContent}>
            <div className={styles.searchWrapper}>
              <TextField
                autoFocus
                value={search}
                placeholder="Search accounts"
                classes={{ root: styles.searchMembers }}
                InputProps={{
                  classes: {
                    root: styles.inputRoot,
                    input: styles.inputInput,
                    notchedOutline: styles.inputNotchedOutline,
                    focused: styles.inputFocused,
                  },
                }}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={handleKeyDownProcess}
                style={{ width: '100%' }}
                id={`${PAGE_NAME}_Search`}
              />
            </div>
            <div className={styles.sideContainer} ref={listContainerRef}>
              {!search && membersStore.accounts.length > 0 && (
                <TreeSubItem
                  isAllAccount
                  isSelected={value === membersStore.organization.id}
                  isHover={hoverAccountId === membersStore.organization.id}
                  label={membersStore.organization.name}
                  onSelect={() => handleSelectAccount(membersStore.organization)}
                />
              )}
              {!filteredAccountList.length && search && (
                <div className={styles.noMatchingWrapper} id={`${PAGE_NAME}_noMatching_container`}>
                  <SearchIcon className={styles.noMatchingIcon} />
                  <Typography variant="h6">No Matching Criteria</Typography>
                  <Typography variant="body1" className={styles.noMatchingLabel}>
                    Please try another search term.
                  </Typography>
                </div>
              )}
              {filteredAccountList.map((account) => renderSubAccountItem(account, 0))}
            </div>
          </div>
        </Popover>
      </>
    );
  },
);
