import { makeObservable } from 'mobx';
import { MembersStore } from './MembersStore';
import { OrganizationStore } from './OrganizationStore';
import { accountStore } from './AccountStore';
import { userStore } from './UserStore';
import { ScheduleStore } from './ScheduleStore';

export class SettingsStore {
  constructor() {
    makeObservable(this, {});

    this.accountStore = accountStore;
    this.membersStore = new MembersStore();
    this.organizationStore = new OrganizationStore();
    this.routerStore = userStore.routerStore;
    this.scheduleStore = new ScheduleStore();
  }

  dispose() {
    this.accountStore.dispose();
    this.membersStore.dispose();
    this.organizationStore.dispose();
  }
}
