import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'clsx';
import { InputLabel, Popover } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';
import { makeStyles } from '@mui/styles';

import TreeSubItem from '../OrgAccessDropdown/TreeSubItem';
import { getExpandedChildrenCount, getNodeItemFromId } from '@/utils';
import { AnchorOrigin } from '@/utils/types';
import Accordion from '../Accordion';
import AccordionSummary from '../AccordionSummary';
import AccordionDetails from '../AccordionDetails';
import Typography from '../Typography';
import SearchField from '../SearchField';

import styles from './TreeListDropdown.module.css';

const PAGE_NAME = 'TreeListDropdown';

export const TreeListDropdown = observer(
  ({
    placeholder,
    treeList,
    allItems,
    anchorPosition = AnchorOrigin.bottom,
    label,
    selectedValues,
    width = 372,
    disabled = false,
    multiSelect,
    selectCategory,
    onChange,
    className,
  }) => {
    const classes = makeStyles({ paper: { width } })();
    const dropdownRef = useRef();
    const listContainerRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [search, setSearch] = useState('');
    const [hoverItemId, setHoverItemId] = useState(selectedValues?.[0]);
    const firstSelectedItem = useMemo(
      () => getNodeItemFromId(treeList, selectedValues?.[0]),
      [selectedValues?.[0], treeList],
    );
    const popoverPosition =
      anchorPosition === AnchorOrigin.bottom ||
      (anchorPosition === AnchorOrigin.auto &&
        dropdownRef?.current?.getBoundingClientRect().y < window.innerHeight - 356)
        ? { anchorOrigin: 'bottom', transformOrigin: 'top', marginTop: '6px' }
        : { anchorOrigin: 'top', transformOrigin: 'bottom', marginTop: '-6px' };

    useEffect(() => {
      if (!multiSelect && selectedValues?.length > 0) {
        if (Boolean(anchorEl)) {
          let count = getExpandedChildrenCount(treeList, selectedValues?.[0]);
          const timeout = setTimeout(() => {
            listContainerRef.current?.scrollTo({
              top: (count - 1) * 32,
              behavior: 'smooth',
            });
          }, 100);
          return () => clearTimeout(timeout);
        } else {
          setSearch('');
          setHoverItemId(selectedValues[0]);
        }
      }
    }, [anchorEl, selectedValues]);

    const handleClose = () => {
      setAnchorEl(null);
      setSearch('');
    };

    const expandSelectedItem = (node) => {
      const selectedNode = getNodeItemFromId(treeList, node);
      if (!selectedNode) return;

      let categoryList = treeList;
      selectedNode.path.forEach((categoryName) => {
        const index = categoryList.findIndex((item) => item.name === categoryName);
        if (index !== -1) {
          categoryList[index] = { ...categoryList[index], isExpanded: true };
          categoryList = categoryList[index].children;
        }
      });
    };

    const onToggleExpandCategory = (nodeName) => {
      function toggleExpandMore(nodeList, selectedNode) {
        for (let i = 0; i < nodeList.length; i++) {
          const node = nodeList[i];
          if (node.name === selectedNode) {
            nodeList[i] = {
              ...nodeList[i],
              isExpanded: node.isExpanded ? false : true,
            };
            return true;
          }
          if (node.children.length > 0) {
            const result = toggleExpandMore(node.children, selectedNode);
            if (result) return result;
          }
        }
      }
      toggleExpandMore(treeList, nodeName);
    };

    const filteredItemsList = useMemo(() => {
      if (search) {
        return allItems.reduce((res, config) => {
          const startIndex = config.name.toLowerCase().indexOf(search.toLowerCase().trim());
          if (startIndex === -1) return res;
          return [
            ...res,
            {
              ...config,
              match: [startIndex, startIndex + search.length],
            },
          ];
        }, []);
      }
      return treeList;
    }, [search, treeList, allItems]);

    const nodeCategoryItem = (node, layer) => {
      return (
        <Accordion
          key={node.name}
          classes={{
            accordionRoot: styles.accordionRoot,
            accordionExpanded: styles.accordionExpanded,
          }}
          sx={{
            '&:before': {
              display: layer !== 0 && 'none',
            },
          }}
          id={`${PAGE_NAME}_nodes_accordion`}
          expanded={!!node.isExpanded}
        >
          <AccordionSummary
            id={`${PAGE_NAME}_nodes_accordion_content`}
            containerClassName={cx({
              [styles.accordionSummary]: 1,
              [styles.accordionSelectedSummary]: hoverItemId === node.name,
            })}
            contentClassName={styles.accordionSummaryContent}
          >
            <TreeSubItem
              isCheckbox={multiSelect}
              selectCategory={selectCategory}
              checked={selectedValues.includes(node.id)}
              key={node.id}
              isChild={node.children?.length > 0}
              isExpanded={!!node.isExpanded}
              isSelected={selectedValues === node.id}
              isHover={hoverItemId === node.id}
              label={node.displayName || node.name}
              match={node.match}
              onSelect={(e) => {
                if (selectCategory || !node.children?.length) {
                  e.stopPropagation();
                  const value = node.id;
                  const checked = selectedValues.includes(value);
                  if (multiSelect) {
                    onChange(
                      checked
                        ? selectedValues.filter((o) => o !== value)
                        : selectedValues.concat(value),
                    );
                  } else {
                    onChange([value]);
                    setAnchorEl(null);
                  }
                }
              }}
              onToggleExpand={(e) => {
                e.stopPropagation();
                onToggleExpandCategory(node.name);
              }}
            />
          </AccordionSummary>
          {node.children?.length > 0 && (
            <AccordionDetails className={cx(styles.accordionDetails)}>
              {node.children.map((category) => nodeCategoryItem(category, layer + 1))}
            </AccordionDetails>
          )}
        </Accordion>
      );
    };

    const handleDropdownClick = (event) => {
      if (!disabled && treeList.length > 0) {
        expandSelectedItem(selectedValues?.[0]);
        setAnchorEl(event.currentTarget);
      }
    };

    const renderValue = () => {
      if (!selectedValues?.length) {
        return placeholder;
      }
      const selectedLabel = firstSelectedItem.displayName || firstSelectedItem.name || '';
      const label = `${selectedLabel.slice(0, 30)}${selectedLabel.length > 30 ? '...' : ''}`;
      if (selectedValues.length > 1) {
        return `${label} +${selectedValues.length - 1} more`;
      }
      return label;
    };

    const searchChangeHandler = (e) => setSearch(e.target.value);

    const debouncedChangeHandler = useCallback(debounce(searchChangeHandler, 500), []);

    return (
      <>
        {!!label && (
          <InputLabel
            shrink
            htmlFor="component-selection-label"
            classes={{
              root: styles.selectLabel,
            }}
          >
            {label}
          </InputLabel>
        )}
        <div
          ref={dropdownRef}
          className={cx(
            styles.defaultDropdown,
            {
              [styles.selected]: Boolean(anchorEl),
              [styles.disabled]: disabled || treeList.length === 0,
            },
            className,
          )}
          onClick={handleDropdownClick}
        >
          <Typography
            variant="body2"
            className={cx(styles.truncatedText, {
              [styles.selectLabelDisabled]: !firstSelectedItem,
            })}
          >
            {renderValue()}
          </Typography>
          <div
            className={cx(styles.moreDefaultIcon, {
              [styles.moreDefaultRotate]: Boolean(anchorEl),
            })}
          >
            <ExpandMoreIcon
              className={cx(styles.moreDefaultSvg, {
                [styles.selectLabelDisabled]: disabled,
              })}
            />
          </div>
        </div>

        <Popover
          anchorEl={anchorEl}
          id={`${PAGE_NAME}_Popover`}
          open={Boolean(anchorEl)}
          anchorOrigin={{
            vertical: popoverPosition.anchorOrigin,
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: popoverPosition.transformOrigin,
            horizontal: 'center',
          }}
          onClose={handleClose}
          classes={{ paper: cx(styles.paper, classes.paper) }}
          sx={{ marginTop: popoverPosition.marginTop }}
        >
          <div className={styles.popoverContent}>
            <div className={styles.searchWrapper}>
              <SearchField
                isDarkTheme
                id="tree-log-search"
                placeholder="Search"
                value={search}
                onChange={debouncedChangeHandler}
                sx={{ width: '100%' }}
              />
            </div>
            <div className={styles.sideContainer} ref={listContainerRef}>
              {!filteredItemsList.length && search && (
                <div className={styles.noMatchingWrapper} id={`${PAGE_NAME}_noMatching_container`}>
                  <SearchIcon className={styles.noMatchingIcon} />
                  <Typography variant="h6">No Matching Criteria</Typography>
                  <Typography variant="body2" className={styles.noMatchingLabel}>
                    Please try another search term.
                  </Typography>
                </div>
              )}
              {filteredItemsList.map((config) => nodeCategoryItem(config, 0))}
            </div>
          </div>
        </Popover>
      </>
    );
  },
);
