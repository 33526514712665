import PropTypes from 'prop-types';
import cx from 'clsx';
import TableCell from '@mui/material/TableCell';
import { useStyles } from './sharedStyles';

export const TextCell = ({ column, value, match = null, isLastRow }) => {
  const styles = useStyles();

  return (
    <TableCell
      key={column}
      className={cx(styles.bodyCell, styles.textCell, { [styles.bodyLastRightCell]: isLastRow })}
    >
      <div className={styles.textLabel}>
        {match ? (
          <>
            {value.slice(0, match[0])}
            <span style={{ fontWeight: 700, backgroundColor: '#E8E4F9' }}>
              {value.slice(match[0], match[1])}
            </span>
            {value.slice(match[1])}
          </>
        ) : (
          value
        )}
      </div>
    </TableCell>
  );
};

TextCell.propTypes = {
  column: PropTypes.string.isRequired,
  match: PropTypes.arrayOf(PropTypes.number),
};

TextCell.defaultProps = {
  match: null,
};
