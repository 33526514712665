import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import { useStyles } from './sharedStyles';
import { CopyAllOutlined } from '@mui/icons-material';
import { showSuccessNotification } from '@/utils';

export const TicketLinkCell = ({ column, value, link, showCopy }) => {
  const styles = useStyles();

  return (
    <TableCell key={column} className={styles.bodyCell}>
      <div className={styles.expandCell}>
        <Link
          to={link}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {value}
        </Link>
        {showCopy && (
          <CopyAllOutlined
            fontSize="small"
            onClick={async (e) => {
              await navigator.clipboard.writeText(value);
              showSuccessNotification(`Copied Id ${value}`, { autoHideDuration: 1000 });
            }}
          />
        )}
      </div>
    </TableCell>
  );
};

TicketLinkCell.propTypes = {
  column: PropTypes.string.isRequired,
};
