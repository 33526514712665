import { observer } from 'mobx-react-lite';
import { Modal } from '@/components';
import CreateTicketPage from './CreateTicketPage';
import { withStore } from '@/store';

import styles from './CreateTicketModal.module.css';

const CreateTicketModal = ({ open, handleClose }) => {
  return (
    <Modal
      containerClassName={styles.modalContainer}
      contentClassName={styles.modalContent}
      open={open}
      onClose={handleClose}
    >
      <CreateTicketPage onClose={handleClose} />
    </Modal>
  );
};

export default withStore(observer(CreateTicketModal));
