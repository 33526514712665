import HelpdeskColorLightIcon from '../assets/logo/helpdesk-color-light.svg';

export const FetchOptions = {
  stream: { isStream: true },
  status: { isStatus: true },
  noAuth: { hasNoAuth: true },
};

export const StatusCode = {
  ok: 200,
  noContent: 204,
  badRequest: 400,
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
};

export const DEFAULT_BRANDING_INFO = {
  organizationId: null,
  logoUrl: HelpdeskColorLightIcon,
  alias: 'notifications@workflowlabs.net',
  eMail: 'notifications@workflowlabs.net',
  helpUrl: 'https://www.notion.so/Welcome-to-Workflow-Labs-1fa7a55313094f23b4553afbf2d512c5',
};
