import { orderBy } from 'lodash';
import { MAX_QUANTITY } from './constants';

export function getExpandedChildrenCount(accounts, selectedAccountId) {
  let count = 0;
  for (let i = 0; i < accounts.length; i += 1) {
    const account = accounts[i];
    count += 1;
    if (account.id === selectedAccountId) {
      return count;
    }
    if (account.isExpanded && account.children.length > 0) {
      count += getExpandedChildrenCount(account.children, selectedAccountId);
      return count;
    }
  }
  return 0;
}

export function isValidQuantity(quantity) {
  return Number(quantity) > 0 && Number(quantity) < MAX_QUANTITY;
}

export function pluralToSingular(plural) {
  const irregularPlurals = {
    children: 'child',
  };

  if (irregularPlurals.hasOwnProperty(plural)) {
    return irregularPlurals[plural];
  }

  const rules = [
    [/(quiz)zes$/i, '$1'],
    [/([m|l])ice$/i, '$1ouse'],
    [/(matr|vert|ind)ices$/i, '$1ix'],
    [/(x|ch|ss|sh)es$/i, '$1'],
    [/s$/i, ''],
  ];

  for (const [pattern, replacement] of rules) {
    if (pattern.test(plural)) {
      return plural.replace(pattern, replacement);
    }
  }

  return plural;
}

export function getOrganizationInfo(orgData) {
  if (!orgData) return null;
  const {
    profile: { eventSubscriptions, logins, settings, services },
  } = orgData;

  return {
    ...orgData,
    profile: {
      ...orgData.profile,
      logins: logins || [],
      eventSubscriptions: Object.entries(eventSubscriptions ?? {}).map(([event, link]) => ({
        event,
        link,
      })),
      settings: settings || { isCaptcha: false },
      services: services || [],
    },
  };
}

export const sortNodes = (nodes) => {
  const sortedAccounts = orderBy(nodes, 'name', 'asc');
  return sortedAccounts.map((node) => ({
    ...node,
    children: node.children.length > 0 ? sortNodes(node.children) : [],
  }));
};

export const makeChildrenTree = (nodes, isSort = true) => {
  const result = { children: [] };
  const inactivatedIds = [];
  nodes.forEach((node) => {
    let currentNode = result;
    node.path.every((nodeId, index) => {
      const nextNode = currentNode.children.find((item) => item.id === nodeId);
      if (!nextNode?.id) {
        const newItem = nodes.find((item) => item.id === nodeId);
        if (!newItem?.id) {
          inactivatedIds.push(...node.path.slice(index));
          return false;
        } else {
          currentNode.isExpandable = true;
          currentNode.children.push(newItem);
        }
      } else {
        currentNode = nextNode;
      }
      return true;
    });
  });
  const children = result.children?.[0]?.children;
  return { children: isSort ? sortNodes(children) : children, inactivatedIds };
};

export const updateTicketLogs = (tickets, ticket) => {
  for (let i = 0; i < tickets.length; i++) {
    const { id, children } = tickets[i];
    if (id === ticket.id) {
      tickets[i] = {
        ...tickets[i],
        ...ticket,
      };
      return true;
    }
    if (children?.length > 0) {
      const result = updateTicketLogs(children, ticket);
      if (result) return true;
    }
  }
};
