import { Stack } from '@mui/material';

import { TextCopyField } from './TextCopyField';

const PLATFORM_NAME = 'GeneralPlatform';

export const GeneralPlatform = ({ username, onUsernameChange, showError, disabled }) => {
  const handleChangeUsername = (e) => {
    onUsernameChange(e.target.value);
  };

  const handleCopyText = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <Stack spacing={1} id={`${PLATFORM_NAME}_container`}>
      <TextCopyField
        label="User Name*"
        value={username ?? ''}
        error={showError && !username}
        helperText={showError && !username ? 'Please provide your name.' : null}
        id={`${PLATFORM_NAME}_userName`}
        size="small"
        disabled={disabled}
        classes={{ flex: 1 }}
        onChange={handleChangeUsername}
        onCopyClick={() => handleCopyText(username)}
      />
    </Stack>
  );
};
