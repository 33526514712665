import { omit, trim } from 'lodash';
import { Config } from '../config';
import { parseUser } from './parseUtils';
import * as fetchWithAuth from './fetchWithAuth';
import { DEFAULT_BRANDING_INFO, FetchOptions } from './constants';
import { userStore } from '../store';
import { getPlatformValue } from '../utils/staticForm';
import { formatLocalDateTime, makeChildrenTree, AssignTypeOptions, mapToUserOption } from '@/utils';

function parseLookupsResponse(obj, organizationId) {
  return {
    organizationId,
    assignees: Object.entries(obj.assignables ?? {}).map(([id, info], index) => {
      const [name, email] = info.split(',').map(trim);
      return mapToUserOption({ id: +id, name, email, type: index % AssignTypeOptions.length });
    }),
    states: Object.entries(obj.states).map(([id, name]) => ({ id: +id, name: trim(name) })),
    services: (obj.services ?? []).map((workflow) => ({
      value: workflow,
      label: workflow,
    })),
    automation: (obj.automation ?? []).map((state) => ({
      value: state,
      label: state,
    })),
    platforms: Object.entries(obj.platforms).map(([id, name]) => ({
      id: +id,
      name,
    })),
  };
}

export const getOrgLookups = async (orgId) => {
  return fetchWithAuth
    .get(`${Config.NEW_API}/Internal/lookups/${orgId}`)
    .then((resp) => parseLookupsResponse(resp, orgId));
};

export const getOrganizationUsers = async (organizationId, fullyHydrated = true) => {
  if (!organizationId) return [];

  return fetchWithAuth
    .get(`${Config.NEW_API}/Organizations/${organizationId}/users?fullyHydrated=${fullyHydrated}`)
    .then((resp) => resp.items.map(parseUser));
};

function getOrgPlatformList(organizationInfo) {
  if (!userStore.platformTypes?.length) return {};
  const logins = organizationInfo?.profile?.logins ?? [];
  const orgPlatformLabels = logins
    .map(
      ({ platform }) => userStore.platformTypes.find(({ id }) => id === platform)?.name ?? platform,
    )
    .join(', ');
  const orgPlatformNames = logins.map(({ platform }) => getPlatformValue(platform)).join(', ');
  return { orgPlatformLabels, orgPlatformNames };
}

function parseOrganization(obj, grandParentPath = []) {
  const org = obj.record ?? obj;
  const path = org.path
    .split('/')
    .filter((id) => id)
    .map(Number)
    .filter((id) => !grandParentPath.includes(id));
  const branding = obj.profile?.branding;
  const pathByName = org.pathByName;

  return {
    id: org.id,
    name: org.organization,
    parentId: org.parentId,
    grandParentPath,
    path,
    createdOn: formatLocalDateTime(org.createdOn),
    isActive: org.isActive,
    children: [],
    pathByName: org.pathByName,
    parentPath: pathByName && pathByName.split('/').slice(0, -2).join('/'),
    ...getOrgPlatformList(obj),
    profile: {
      ...obj.profile,
      branding: {
        alias: branding?.alias ?? '',
        logoUrl: branding?.logoUrl || DEFAULT_BRANDING_INFO.logoUrl,
        mailFrom: branding?.eMail ?? '',
        helpUrl: branding?.supportUrl || DEFAULT_BRANDING_INFO.helpUrl,
      },
    },
  };
}

export const getOrganization = async (orgId) => {
  return fetchWithAuth
    .get(`${Config.NEW_API}/Internal/organization/${orgId}`)
    .then((resp) => omit(parseOrganization(resp), 'children'));
};

export const createOrganization = async (orgInfo) => {
  return fetchWithAuth.post(`${Config.NEW_API}/Organizations`, orgInfo).then((resp) => resp);
};

export const updateOrganization = async (orgId, orgInfo) => {
  return fetchWithAuth
    .put(`${Config.NEW_API}/Organizations/${orgId}`, orgInfo, FetchOptions.status)
    .then((resp) => resp);
};

function parseInternalOrg(pathStr, data, grandParentPath = []) {
  const path = pathStr
    .split('/')
    .filter((id) => id)
    .map(Number)
    .filter((id) => !grandParentPath.includes(id));
  const pathByName = '/' + path.map((id) => data[id]).join('/');
  const id = path.at(-1);
  const parentId = path.at(-2);

  return {
    id,
    name: data[pathStr],
    parentId,
    grandParentPath,
    path,
    children: [],
    pathByName,
    parentPath: pathByName && pathByName.split('/').slice(0, -1).join('/'),
  };
}

export const getOrganizationFullTree = async (orgId) => {
  return fetchWithAuth.get(`${Config.NEW_API}/Internal/organizations`).then((data) => {
    const parentPathStr = Object.keys(data)[0];
    const parentPathIds = parentPathStr
      .split('/')
      .filter((id) => id)
      .map(Number);
    const organizationId = parentPathIds.at(-1);
    const parentPath = parentPathIds.filter((id) => id !== organizationId);

    const arrangedAccounts = Object.keys(data).map((path) =>
      parseInternalOrg(path, data, parentPath),
    );

    const orgsTree = makeChildrenTree(arrangedAccounts);

    const result = {
      parent: parseInternalOrg(parentPathStr, data, parentPath),
      children: orgsTree.children,
      arrangedAccounts: arrangedAccounts.filter(({ id }) => !orgsTree.inactivatedIds.includes(id)),
    };

    return result;
  });
};

export const getPlatformConfiguration = async (userName, platform) => {
  if (!userName || !platform) return {};
  return fetchWithAuth
    .get(`${Config.NEW_API}/Organizations/platform/login?userName=${userName}&platform=${platform}`)
    .then((resp) => resp.configuration)
    .catch(() => ({}));
};

export const getOtpCode = async (otpKey) => {
  if (!otpKey) return '';
  return fetchWithAuth
    .get(`${Config.NEW_API}/Organizations/platform/get_otp_code?otpKey=${otpKey}`)
    .then((resp) => resp)
    .catch(() => '');
};

export const savePlatformLogins = async (logins) => {
  return fetchWithAuth
    .post(`${Config.NEW_API}/Organizations/platform/logins`, logins, FetchOptions.status)
    .then((resp) => resp);
};
