import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { makeStyles } from '@mui/styles';
import { InputAdornment, TextField } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '../Tooltip';

const useStyles = makeStyles((theme) => ({
  textFieldLabelDisabled: {
    color: `${theme.palette.neutrals.greyMed} !important`,
  },
  multiLineEdit: {
    '&:before': {
      borderBottom: 'none !important',
    },
    '&:after': {
      borderBottom: 'none !important',
    },
    '& .MuiInputLabel-root': {
      fontFamily: 'Open Sans !important',
      fontWeight: '600 !important',
      fontSize: '14px !important',
      lineHeight: '19px !important',
      color: `${theme.palette.secondary.darkBlue} !important`,
      transform: 'none !important',
    },
    '& .MuiFormHelperText-root': {
      marginTop: '8px',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '143%',
      textAlign: 'right',
      color: theme.palette.neutrals.greyMed,
    },
    '& .MuiInput-root': {
      marginTop: '24px',
      borderRadius: '20px',
      padding: '10px 24px',
      fontFamily: 'Open Sans !important',
      fontSize: '16px !important',
      fontWeight: '400 !important',
      lineHeight: '24px !important',
      letterSpacing: '0.15px !important',
      color: `${theme.palette.secondary.darkBlue} !important`,
      '&:before': {
        borderWidth: '0px !important',
      },
      '&:after': {
        borderWidth: '0px !important',
      },
      '& .MuiSvgIcon-root': {
        color: `${theme.palette.neutrals.greyMed} !important`,
        marginRight: '-16px',
      },
      '&.Mui-focused': {
        border: `1px solid ${theme.palette.primary.purple}`,
      },
    },
  },
  textFieldDark: {
    '& .MuiInput-root': {
      backgroundColor: '#F0F2F7 !important',
      border: '1px solid #F0F2F7 !important',
    },
  },

  textFieldNormal: {
    '& .MuiInput-root': {
      backgroundColor: '#FFFFFF',
      border: '1px solid #FFFFFF',
    },
  },

  hiddenLabel: {
    '& .MuiInput-root': {
      marginTop: '0px',
    },
  },
  disabled: {
    '& .MuiInputLabel-root': {
      color: `${theme.palette.neutrals.greyMed} !important`,
    },
    '& .MuiInput-root': {
      borderColor: `#F8F9FE !important`,
      backgroundColor: `#F8F9FE !important`,
    },
    '& .MuiInput-input': {
      '-webkit-text-fill-color': `${theme.palette.neutrals.grayBlueDark} !important`,
    },
  },
  smallEditBox: {
    '& .MuiFormHelperText-root': {
      marginTop: '4px',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '22px !important',
      color: theme.palette.neutrals.greyMed,
    },
    '& .MuiInput-root': {
      marginTop: '0px',
      borderRadius: '8px',
      padding: '0px 8px',
      fontSize: '14px !important',
    },
  },
  error: {
    '& .MuiInputLabel-root': {
      color: `${theme.palette.ui.error} !important`,
    },
    '& .MuiInput-root': {
      color: `${theme.palette.ui.error} !important`,
      border: `1px solid ${theme.palette.ui.error}`,
      '&.Mui-focused': {
        border: `1px solid ${theme.palette.ui.error} !important`,
      },
    },
    '& .MuiFormHelperText-root': {
      color: `${theme.palette.ui.error} !important`,
    },
  },
  tooltip: {
    maxWidth: '170px !important',
  },
  tooltipIcon: {
    paddingLeft: 8,
    fontSize: '13px !important',
  },
}));

const MultiLineInput = (props) => {
  const {
    variant,
    isDarkTheme,
    isTransparent,
    label,
    tooltipText,
    error,
    size,
    disabled,
    placeholder,
    isHelper,
    maxLength,
    onChange,
    EndIcon,
    ...rest
  } = props;

  const styles = useStyles();
  const [helperText, setHelperText] = useState(null);
  const [currentValue, setCurrentValue] = useState(props.defaultValue || null);

  useEffect(() => {
    if (isHelper && maxLength) {
      setHelperText(`${currentValue ? currentValue.length : 0}/${maxLength}`);
    }
  }, [currentValue, maxLength, isHelper]);

  const handleChange = (event) => {
    setCurrentValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <TextField
      {...rest}
      multiline
      label={
        <Tooltip placement="right" arrow title={tooltipText} className={styles.tooltip}>
          <div>
            {label}
            {tooltipText && <InfoIcon className={styles.tooltipIcon} />}
          </div>
        </Tooltip>
      }
      placeholder={placeholder}
      helperText={helperText}
      variant={variant}
      InputLabelProps={{ shrink: true }}
      inputProps={{ maxLength }}
      error={error}
      disabled={disabled}
      margin={size === 'small' || !label ? 'none' : 'normal'}
      classes={{
        root: cx(styles.multiLineEdit, {
          [styles.smallEditBox]: size === 'small',
          [styles.hiddenLabel]: !label,
          [styles.error]: error,
          [styles.disabled]: disabled,
          [styles.textFieldNormal]: !isTransparent,
          [styles.textFieldDark]: isDarkTheme,
        }),
      }}
      onChange={handleChange}
      InputProps={{
        endAdornment: <InputAdornment position="start">{EndIcon}</InputAdornment>,
      }}
    />
  );
};

MultiLineInput.propTypes = {
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  isDarkTheme: PropTypes.bool,
  label: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  isHelper: PropTypes.bool,
  onChange: PropTypes.func,
  EndIcon: PropTypes.node,
};

MultiLineInput.defaultProps = {
  variant: 'standard',
  isDarkTheme: false,
  label: null,
  error: false,
  disabled: false,
  placeholder: '',
  maxLength: null,
  isHelper: false,
  onChange: () => {},
  EndIcon: null,
};

export default MultiLineInput;
