import { DataGridTable, Typography } from '@/components';
import { TRIGGERS_COLUMNS } from '../tableColumns';
import { useMemo } from 'react';
import { getTicketLink } from '@/utils';
import { ColumnType } from '@/utils/types';

import styles from './TriggersTable.module.css';

export default function TriggersTable({ rows, orgId }) {
  const columns = useMemo(
    () =>
      TRIGGERS_COLUMNS.map((column) => {
        if (column.field === ColumnType.id) {
          return {
            ...column,
            renderCell: ({ value: ticketId }) => (
              <a href={getTicketLink(ticketId)} target="_blank" rel="noopener noreferrer">
                {ticketId}
              </a>
            ),
          };
        }
        return column;
      }),
    [orgId],
  );
  return (
    <>
      <Typography variant="subtitle2" className={styles.title}>
        Triggered:
      </Typography>
      {!rows?.length ? (
        <Typography variant="body2" className={styles.indent}>
          No tickets
        </Typography>
      ) : (
        <DataGridTable
          id="triggerTable"
          rows={rows}
          columns={columns}
          hideFooter
          className={styles.indent}
        />
      )}
    </>
  );
}
