import { DEFAULT_CRONS } from '@/utils/constants';
import { AutomationStatusIcons, ColumnType } from '../../../utils/types';
import { formatLocalDateTime } from '@/utils';
import { AutomationLog } from '@/components/Table/AutomationLog';

const getDateValue = (value) => (value ? formatLocalDateTime(value, 'MM/DD/YYYY') : '');

export const SCHEDULE_COLUMNS = [
  {
    field: ColumnType.cron,
    headerName: 'Cron',
    type: 'singleSelect',
    valueOptions: DEFAULT_CRONS,
    editable: true,
    maxWidth: 360,
    flex: 1,
    sortable: false,
  },
  {
    field: ColumnType.paused,
    headerName: 'Paused',
    renderCell: ({ row }) => (row.paused ? '✓' : ''),
    type: 'singleSelect',
    valueOptions: [true, false],
    width: 84,
    editable: true,
    sortable: false,
  },
  {
    field: ColumnType.lastRun,
    headerName: 'Last Run',
    type: 'date',
    valueFormatter: getDateValue,
    width: 120,
    sortable: false,
  },
  {
    field: ColumnType.createdOn,
    headerName: 'Created Date',
    type: 'date',
    valueFormatter: getDateValue,
    width: 120,
    sortable: false,
  },
];

export const TRIGGERS_COLUMNS = [
  {
    field: ColumnType.id,
    headerName: 'Id',
    width: 72,
    sortable: false,
  },
  {
    field: ColumnType.state,
    headerName: 'Status',
    width: 100,
    sortable: false,
  },
  {
    field: ColumnType.title,
    headerName: 'Title',
    flex: 1,
    minWidth: 100,
    sortable: false,
  },
  {
    field: ColumnType.createdOn,
    headerName: 'Triggered On',
    width: 112,
    sortable: false,
    valueFormatter: getDateValue,
  },
  {
    field: ColumnType.automation,
    headerName: 'Automation',
    sortable: false,
    width: 100,
    renderCell: ({ value: automation }) => <AutomationLog automation={automation} isAdminUser />,
  },
];

export const TICKETS_COLUMNS = [
  {
    field: ColumnType.id,
    headerName: 'Id',
    width: 72,
    sortable: false,
  },
  {
    field: ColumnType.state,
    headerName: 'Status',
    width: 100,
    sortable: false,
  },
  {
    field: ColumnType.organization,
    headerName: 'Account',
    width: 120,
    sortable: false,
  },
  {
    field: ColumnType.workflow,
    headerName: 'Service',
    width: 120,
    sortable: false,
  },
  {
    field: ColumnType.title,
    headerName: 'Title',
    width: 320,
    sortable: false,
  },
  {
    field: ColumnType.createdOn,
    headerName: 'Date Created',
    width: 112,
    sortable: false,
    valueFormatter: getDateValue,
  },
  {
    field: ColumnType.updatedOn,
    headerName: 'Updated',
    width: 104,
    sortable: false,
    valueFormatter: getDateValue,
  },
  {
    field: ColumnType.automation,
    headerName: 'Automation',
    sortable: false,
    width: 100,
    valueFormatter: (name) => AutomationStatusIcons?.[name] ?? AutomationStatusIcons.none,
  },
  {
    field: ColumnType.buildError,
    headerName: 'Issue',
    width: 320,
    sortable: false,
  },
];
