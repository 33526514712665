import { useEffect } from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import { noop } from "lodash";
import { isMobileDetected } from "@/utils";

import { SnackbarContent, Typography, Grow, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useStyles } from "./styles.js";

const Toast = ({ classes, onDismissClick, toast, ...other }) => {
  const {
    autoHideDuration = 5000,
    content,
    id,
    onClick = () => {},
    variant = "success",
    onClose = noop,
  } = toast;

  useEffect(() => {
    const timeout = setTimeout(() => onDismissClick(id), autoHideDuration);

    return () => clearTimeout(timeout);
  }, [autoHideDuration, id, onDismissClick]);

  const styles = useStyles();

  return (
    <Grow appear in>
      <SnackbarContent
        classes={{
          root: cx(styles.main, styles[variant], {
            [styles.mainMobile]: isMobileDetected,
          }),
          message: styles.messageContainer,
        }}
        aria-describedby="client-snackbar"
        message={
          <div
            id="client-snackbar"
            className={styles.messageWrapper}
            onClick={onClick}
          >
            <div className={styles.message}>
              <div className={cx(styles.messageContent, styles[variant])}>
                <Typography id="alert_content" className={styles.content}>
                  {content}
                </Typography>
              </div>
            </div>
          </div>
        }
        action={[
          <IconButton
            id="alert_closeButton"
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => {
              onClose();
              onDismissClick(id);
            }}
            size="small"
            className={styles.closeIconButton}
          >
            <CloseIcon className={styles.closeIcon} htmlColor="#fff" />
          </IconButton>,
        ]}
        {...other}
      />
    </Grow>
  );
};

Toast.propTypes = {
  onDismissClick: PropTypes.func.isRequired,
  toast: PropTypes.shape({
    autoHideDuration: PropTypes.number,
    content: PropTypes.node.isRequired,
    icon: PropTypes.node,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    onClick: PropTypes.func,
    variant: PropTypes.oneOf(["success", "error"]),
    onClose: PropTypes.func,
  }).isRequired,
};

export default Toast;
