const palette = {
  primary: {
    main: '#03BCD4',
    purple: '#5D36EF',
    darkPurple: '#3A1C9D',
    text1: '#000000',
  },
  secondary: {
    main: '#F50056',
    salmon: '#FF917B',
    lightBlue: '#2FD6EE',
    lightPurple: '#B39DFF ',
    darkBlue: '#081949',
    contrast: '#FFFFFF',
  },
  gradients: {
    purple: 'linear-gradient(180deg, #7C36EF 0%, #5D36EF 100%)',
    darkPurple: 'linear-gradient(180deg, #5E2DAE 0%, #3A1C9D 100%)',
  },
  neutrals: {
    white: '#FFFFFF',
    lightGrey: '#D3D3D3',
    grey: '#242424',
    tableLightGray: '#F8F9FE',
    grayBlue: '#F0F2F7',
    grayBlueDark: '#4B4E57',
    greyMed: '#AFAFAF',
  },
  ui: {
    error: '#F34E4E',
    success: '#A2D4A1',
    dragging: '#FFFFFF42',
  },
  options: {
    hover: '#E8E4F9',
    selected: '#B39DFF',
  },
};

(function setPaletteAsCSS(obj, name) {
  if (typeof obj === 'object') {
    Object.entries(obj).forEach(([key, value]) => setPaletteAsCSS(value, name + `-${key}`));
  } else if (typeof obj === 'string') {
    document.documentElement.style.setProperty(name, obj);
  } else {
    throw new Error('Unexpected styles type!');
  }
})(palette, '--palette');

export default palette;
