const defaultConfig = require('../../default.json');
const devConfig = require('../../default.dev.json');
const prodConfig = require('../../default.prod.json');
const stageConfig = require('../../default.stage.json');
const version = require('../../../package.json').version;

function Config() {
  const appURL = window.location.origin;

  if (appURL.startsWith(defaultConfig.REACT_APP_LINK)) return { ...defaultConfig, version };
  if (appURL.startsWith(devConfig.REACT_APP_LINK)) return { ...devConfig, version };
  if (appURL.startsWith(stageConfig.REACT_APP_LINK)) return { ...stageConfig, version };
  if (appURL.startsWith(prodConfig.REACT_APP_LINK)) return { ...prodConfig, version };
  return { ...devConfig, version };
}

export default Config();
