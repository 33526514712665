import { Button } from '@mui/material';
import clsx from 'clsx';
import Tooltip from '../Tooltip/Tooltip';

import styles from './TooltipButton.module.css';

export default function TooltipButton({ tooltipText, variant, children, ...otherProps }) {
  return (
    <Tooltip title={tooltipText} arrow>
      <Button
        variant={variant}
        classes={{
          root: clsx(styles.button, {
            [styles.secondaryButton]: variant === 'secondary',
            [styles.primaryButton]: variant === 'primary',
          }),
          disabled: styles?.[`${variant}Disabled`],
        }}
        {...otherProps}
      >
        {children}
      </Button>
    </Tooltip>
  );
}
