import { PanelResizeHandle } from 'react-resizable-panels';
import cx from 'clsx';

import styles from './styles.module.css';

export function ResizeHandle({ className = '', id }) {
  return (
    <PanelResizeHandle
      className={cx(styles.ResizeHandleOuter, className)}
      id={id}
      style={{ cursor: 'ns-resize !important' }}
    >
      <div className={styles.ResizeHandleInner} />
    </PanelResizeHandle>
  );
}
