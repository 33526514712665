import PropTypes from 'prop-types';
import cx from 'clsx';
import MuiRadio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  checkbox: {
    color: `${theme.palette.secondary.darkBlue} !important`
  },
  checked: {
    color: `${theme.palette.primary.purple} !important`
  },
  error: {
    color: `${theme.palette.ui.error} !important`
  },
  disabled: {
    color: `${theme.palette.neutrals.greyMed} !important`
  },
  label: {
    fontFamily: 'Open Sans !important',
    fontWeight: '400 !important',
    fontSize: '14px !important',
    letterSpacing: '0.15px !important',
    color: `${theme.palette.neutrals.grey} !important`
  }
}));

const Radio = ({
  disabled,
  error,
  checked,
  label,
  onClick,
  onChange,
  ...props
}) => {
  const styles = useStyles();

  return (
    <FormControlLabel
      control={
        <MuiRadio
          {...props}
          checked={checked}
          disabled={disabled}
          classes={{
            root: cx(styles.checkbox, {
              [styles.checked]: checked,
              [styles.error]: error,
              [styles.disabled]: disabled
            })
          }}
          onClick={onClick}
          onChange={onChange}
        />
      }
      label={label}
      classes={{
        label: cx(styles.label, {
          [styles.error]: error,
          [styles.disabled]: disabled
        })
      }}
      {...props}
    />
  );
};

Radio.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  checked: PropTypes.bool,
  label: PropTypes.string
};

Radio.defaultProps = {
  disabled: false,
  error: false,
  checked: false,
  label: ''
};

export default Radio;
