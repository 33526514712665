import { makeObservable, observable, action, computed } from 'mobx';
import { omit } from 'lodash';
import { UrlSearch } from '@/utils/types';
import { userStore } from './UserStore';

export class RouterStore {
  searchParams = null;
  setSearchParams = undefined;
  currentPage = '';
  isHideBanner = false;

  constructor() {
    makeObservable(this, {
      searchParams: observable,
      reInitSearch: action,

      search: computed,
      setSearch: action,
      setSearchParam: action,
      removeSearchParam: action,
      setSearchChunkParams: action,

      currentPage: observable,
      setCurrentPage: action,

      isHideBanner: observable,
      setHideBanner: action,
    });
  }

  get search() {
    if (!this.searchParams) {
      return {};
    }
    return Object.fromEntries(this.searchParams.entries());
  }

  setSearch(search) {
    this.setSearchParams?.(search);
  }

  getSearchParam(key) {
    return this.search[key] || '';
  }

  setSearchParam(key, value) {
    this.setSearch({ ...this.search, [key]: value });
  }

  removeSearchParam(key) {
    this.setSearch(omit(this.search, [key]));
  }

  removeSearchParams(keys) {
    this.setSearch(omit(this.search, keys));
  }

  getSearchChunkParams() {
    return this.search;
  }

  setSearchChunkParams(params) {
    this.setSearch(params);
  }

  setCurrentPage(path) {
    this.currentPage = path;
  }

  // NOTE: Global functions
  lastPageLink(page) {
    return userStore.getPageSettings(page).path || '';
  }

  setHideBanner(value) {
    this.isHideBanner = value;
  }

  getMainParams(prefix) {
    const bannerParam = this.isHideBanner ? `${prefix}${UrlSearch.hideBanner}=true` : '';
    return bannerParam;
  }

  dispose() {}

  reInitSearch(searchParams, setSearchParams) {
    this.searchParams = searchParams;
    this.setSearchParams = setSearchParams;
  }
}
