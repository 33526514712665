import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { loadingImg } from "@/utils/constants";

const useStyles = makeStyles({
  spinner: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  overlapScreen: {
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1500,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(12, 26, 66, 0.6) !important",
  },
});

const LoadingIndicator = ({ fullScreen, overlapScreen, width }) => {
  const styles = useStyles();

  return (
    <div
      className={overlapScreen ? styles.overlapScreen : styles.spinner}
      style={{ height: fullScreen || overlapScreen ? "100vh" : "100%" }}
    >
      <img width={width} src={loadingImg} alt="Loading..." />
    </div>
  );
};

LoadingIndicator.propTypes = {
  fullScreen: PropTypes.bool,
  width: PropTypes.number,
};

LoadingIndicator.defaultProps = {
  fullScreen: false,
  width: null,
};

export default LoadingIndicator;
