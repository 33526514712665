import PropTypes from 'prop-types';
import cx from 'clsx';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import './SideMenu.css';
import SideMenuItemPopover from './SideMenuItemPopover';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  navItemContainer: {
    color: 'rgba(255,255,255,0.6)',
    fontSize: 16,
    letterSpacing: 0.15,
    fontWeight: 400,
    textTransform: 'capitalize',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    height: '32px',
  },
  allItemHover: {
    '&:hover': {
      color: '#ffffff',
      background: '#132c73 !important',
      fontWeight: '400',
    },
  },
  childItemWithMore: {
    flex: 1,
  },
  childItem: {
    flex: 1,
    '&:hover': {},
  },
  itemSelected: {
    background: `#132c73 !important`,
    color: `rgba(255,255,255,0.8) !important`,
    '& .sideWrapper': {
      backgroundColor: '#2FD6EE',
    },
  },
  labelWrapper: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
  },
  label: {
    display: 'flex',
    lineHeight: '24px',
    alignItems: 'center',
  },
  labelSpan: {
    display: 'block',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  moreIcon: {
    padding: '2px',
    transform: 'rotate(-90deg)',
    color: '#fff',
    fontSize: '1rem !important',
    borderRadius: '3px',
    '&:hover': {
      background: `rgba(255,255,255,0.1) !important`,
    },
  },
  selectedMoreIcon: {
    padding: '2px',
    transform: 'rotate(-90deg)',
    color: '#fff',
    fontSize: '1rem !important',
    borderRadius: '3px',
    '&:hover': {
      background: `rgba(255,255,255,0.1) !important`,
    },
  },
  navOrgIcon: {
    width: '15px !important',
    height: '13px !important',
    background: '#FFF',
    padding: '6px 5px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
    borderRadius: '100px',
    cursor: 'pointer',
    transform: 'rotate(180deg) !important',
    '&:hover': {
      background: `${theme.palette.neutrals.grayBlue} !important`,
    },
  },
  link: {
    textDecoration: 'none',
  },
  popoverContainer: {
    padding: 0,
  },
  arrowContainer: {
    width: '28px',
    height: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const SideMenuItem = ({
  orgId,
  isChild,
  isExpanded,
  isAllAccount,
  isSelected,
  label,
  onSelect,
  onToggleExpand,
  layer,
  search,
  sidebarWidth,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const styles = useStyles();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const itemWidth = sidebarWidth - ((isHovered ? 50 : 20) + 16 * (layer - 1) + 24);

  return (
    <div
      className={cx(styles.navItemContainer, {
        [styles.allItemHover]: isAllAccount,
        [styles.childItemWithMore]: !isAllAccount && isChild,
        [styles.childItem]: !isAllAccount && !isChild,
        [styles.itemSelected]: isSelected,
      })}
      id={`sideNavigation_sideMenuItem_org_${orgId}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.labelWrapper}>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <div className={`sideWrapper`}></div>
          <div style={{ paddingLeft: `${16 * (layer - 1)}px` }}>
            <div className={styles.arrowContainer} style={search ? { width: 8 } : {}}>
              {isChild && layer > 0 && !search && (
                <ExpandMoreIcon
                  className={isSelected ? styles.selectedMoreIcon : styles.moreIcon}
                  style={{ transform: isExpanded && 'rotate(0deg)' }}
                  onClick={onToggleExpand}
                />
              )}
            </div>
          </div>
          <div
            title={label}
            style={{
              width: `${itemWidth}px`,
            }}
            className={styles.label}
            onClick={onSelect}
          >
            <span className={styles.labelSpan}>{label}</span>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          {isHovered && (
            <div
              style={{ display: 'flex' }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <SideMenuItemPopover
                orgId={orgId}
                isChild={isChild}
                isHovered={isHovered && !isSelected}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SideMenuItem.propTypes = {
  isChild: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isAllAccount: PropTypes.bool,
  isSelected: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onToggleExpand: PropTypes.func,
};

SideMenuItem.defaultProps = {
  isChild: false,
  isExpanded: false,
  isAllAccount: false,
  isSelected: false,
  onToggleExpand: undefined,
};

export default SideMenuItem;
