import PropTypes from 'prop-types';
import cx from 'clsx';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display:'flex',
    alignItems:'center',
    color: theme.palette.primary.purple,
    fontFamily: 'Open Sans',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    '&:hover': {
      color: theme.palette.primary.darkPurple,
      cursor: 'pointer',
    },
  },
  defaultDark: {
    color: theme.palette.secondary.lightPurple,
    '&:hover': {
      color: theme.palette.primary.purple,
    },
  },
  visitedLight: {
    color: theme.palette.primary.darkPurple,
  },
  visitedDark: {
    color: theme.palette.primary.purple,
  },
}));

const TextLink = ({
  TooltipProps,
  children,
  className,
  isDarkTheme,
  visited,
  href,
  ...LinkProps
}) => {
  const styles = useStyles();

  return (
    <a
      className={cx(
        styles.root,
        {
          [styles.defaultDark]: isDarkTheme,
          [styles.visitedLight]: visited && !isDarkTheme,
          [styles.visitedDark]: visited && isDarkTheme,
        },
        className,
      )}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      {...LinkProps}
    >
      {children}
    </a>
  );
};

TextLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isDarkTheme: PropTypes.bool,
  visited: PropTypes.bool,
};

TextLink.defaultProps = {
  className: '',
  isDarkTheme: false,
  visited: false,
};

export default TextLink;
