const common = {
  fontFamily: 'Open Sans'
};

export const typography = {
  headline1: {
    ...common,
    fontWeight: 600,
    fontSize: '95px',
    lineHeight: '116.7%',
    letterSpacing: '-1.5px'
  },
  headline2: {
    ...common,
    fontWeight: 600,
    fontSize: '60px',
    lineHeight: '120%',
    letterSpacing: '-0.5px'
  },
  headline3: {
    ...common,
    fontWeight: 600,
    fontSize: '48px',
    lineHeight: '116.7%'
  },
  headline4: {
    ...common,
    fontWeight: 600,
    fontSize: '34px',
    lineHeight: '123.5%',
    letterSpacing: '0.25px'
  },
  headline5: {
    ...common,
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '133.4%'
  },
  headline6: {
    ...common,
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '160%',
    letterSpacing: '0.15px'
  },
  subtitle1: {
    ...common,
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px'
  },
  subtitle2: {
    ...common,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '157%',
    letterSpacing: '0.1px'
  },
  body1: {
    ...common,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px'
  },
  body2: {
    ...common,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.17px'
  },
  buttonLarge: {
    ...common,
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '26px',
    letterSpacing: '0.46px',
    textTransform: 'capitalize'
  },
  buttonMedium: {
    ...common,
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.4px',
    textTransform: 'capitalize'
  },
  buttonSmall: {
    ...common,
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '22px',
    letterSpacing: '0.46px',
    textTransform: 'capitalize'
  },
  buttonExtraSmall: {
    ...common,
    fontWeight: 600,
    fontSize: '11px',
    lineHeight: '20px',
    letterSpacing: '0.46px',
    textTransform: 'capitalize'
  },
  caption: {
    ...common,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.17px'
  },
  captionBold: {
    ...common,
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.17px'
  },
  overline: {
    ...common,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.17px',
    textTransform: 'uppercase'
  }
};
