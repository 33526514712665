import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { cloneDeep, orderBy, trim } from 'lodash';
import { FilterType } from '@/utils/constants';
import { ColumnType } from '@/utils/types';
import { setError } from '@/utils/errors';
import { userStore } from '@/store';

export const InitFilter = {
  [FilterType.service]: {
    key: ColumnType.intentType,
    label: 'Service',
    values: [],
    selectedValues: [],
    hasSearchBar: true,
    hasClear: true,
  },
  [FilterType.lastUpdated]: {
    key: ColumnType.lastUpdated,
    label: 'Last Updated',
    values: [],
    selectedValues: [],
  },
  [FilterType.activeChildren]: {
    key: FilterType.activeChildren,
    label: 'Active Children',
    values: [{ value: true, label: 'Tickets with active children' }],
    selectedValues: [],
  },
};

export class FilterStore {
  filters = cloneDeep(InitFilter);
  search = '';
  filteredSearch = '';

  constructor(accountStore) {
    makeObservable(this, {
      setFilters: action,
      filters: observable,
      updateAllSelectedValues: action,
      filteredResult: computed,
      allFilteredCount: computed,
      search: observable,
      setSearch: action,
      filteredSearch: observable,

      updateSelectedValues: action,
      clearFilter: action,

      filtersQuery: computed,

      fetchFilters: action,
    });

    this.accountStore = accountStore;
  }

  get filtersQuery() {
    const query = Object.keys(this.filteredResult).reduce((res, filterKey) => {
      const value = this.filteredResult[filterKey]?.selectedValues;
      if (value.length === 0) return res;
      return { ...res, [filterKey]: value };
    }, {});
    return query;
  }

  setSearch(value) {
    this.search = trim(value);
  }

  setFilters(filterSets) {
    // Service
    this.filters[FilterType.service].values = filterSets.services;
  }

  get filteredResult() {
    const filteredFilters = this.filters;
    const result = Object.keys(filteredFilters).reduce((result, filterKey) => {
      const values =
        filterKey === FilterType.service
          ? filteredFilters[filterKey].values
          : orderBy(filteredFilters[filterKey].values, (item) => item.label?.toLowerCase(), 'asc');

      return {
        ...result,
        [filterKey]: { ...filteredFilters[filterKey], values },
      };
    }, {});
    return result;
  }

  async clearFilter() {
    const newFilters = Object.keys(this.filters).reduce(
      (result, key) => ({
        ...result,
        [key]: { ...this.filters[key], selectedValues: [] },
      }),
      {},
    );

    this.filters = newFilters;
    this.search = '';
  }

  updateAllSelectedValues(value) {
    this.filters[FilterType.service].selectedValues = value[FilterType.service];
  }

  updateSelectedValues(key, value) {
    this.filters[key].selectedValues = value;
  }

  getSelectedCount(type) {
    return this.filteredResult[type]?.selectedValues?.length ?? 0;
  }

  get allFilteredCount() {
    const count = Object.keys(this.filteredResult).reduce(
      (acc, key) => acc + this.getSelectedCount(key),
      0,
    );
    return count;
  }

  // NOTE: Fetch filters
  async fetchFilters(organizationId) {
    if (!organizationId) return;
    try {
      await userStore.fetchLookupsInfo(organizationId);
      runInAction(() => {
        this.setFilters(userStore.lookupsInfo);
      });
    } catch (err) {
      setError(err, false, 'Get filters failed');
    }
  }

  dispose() {}
}
