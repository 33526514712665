import { observer } from 'mobx-react-lite';
import { Divider } from '@mui/material';
import cx from 'clsx';

import { useSettingsStore, userStore } from '@/store';
import {
  Typography,
  TextField,
  OrgAccessDropdown,
  ImageUploader,
  InfoRow,
  Checkbox,
  TreeListPanel,
} from '@/components';
import { CollapsibleSection } from '@/components/CollapsibleSection';
import { AnchorOrigin } from '@/utils/types';
import { isValidWebLink } from '@/utils';
import { PlatformsContent } from './PlatformsContent';
import EventsContent from './EventsContent';
import { PAGE_MODE } from '@/utils/constants';

import styles from './OrganizationContent.module.css';

const PAGE_NAME = 'OrganizationContent';

const OrganizationContent = ({
  pageMode,
  orgInfo,
  setOrgInfo,
  showError,
  handleChangeOrganization,
}) => {
  const { organizationStore } = useSettingsStore();
  const isOrganizationEditing = pageMode !== PAGE_MODE.none;

  const {
    name: orgName,
    isActive = false,
    createdOn,
    parentPath,
    profile: {
      branding = {},
      eventSubscriptions = [],
      logins,
      settings = { isCaptcha: false },
      services = [],
    },
  } = orgInfo;

  const handleChangeProfile = (param) => {
    handleChangeOrganization({ profile: { ...orgInfo.profile, ...param } });
  };

  const handleChangeBranding = (param) => {
    handleChangeProfile({
      branding: {
        ...branding,
        ...param,
      },
    });
  };

  const handleChangeCaptcha = (isCaptcha) => {
    handleChangeProfile({
      settings: { isCaptcha },
    });
  };

  const handleDropFile = (files) => {
    handleChangeBranding({ logoUrl: files[0], isLogoChanged: true });
  };

  const handleFileInputChange = async (event) => {
    event.preventDefault();
    handleChangeBranding({
      logoUrl: event.target.files[0],
      isLogoChanged: true,
    });
  };

  return (
    <div className={styles.accountPageContainer}>
      <div className={styles.organizationSettings}>
        <div id={`${PAGE_NAME}_headerContainer`} className={styles.infoGroupContainer}>
          <InfoRow label="Created">
            <Typography variant="body2">{createdOn}</Typography>
          </InfoRow>
          <InfoRow label="Parent">
            {!isOrganizationEditing && <Typography variant="body2">{parentPath}</Typography>}
            {isOrganizationEditing && (
              <OrgAccessDropdown
                anchorPosition={AnchorOrigin.bottom}
                value={organizationStore.parentOrgId}
                error={showError && !userStore.organizationId}
                className={styles.orgDropdown}
                onChange={(accountId) => organizationStore.setParentOrgId(accountId)}
              />
            )}
          </InfoRow>
          <InfoRow label="Is Active">
            <Checkbox
              checked={isActive}
              onChange={(e) => handleChangeOrganization({ isActive: e.target.checked })}
              disabled={!isOrganizationEditing}
            />
          </InfoRow>
          <InfoRow label="Is Captcha">
            <Checkbox
              checked={settings?.isCaptcha}
              onChange={(e) => handleChangeCaptcha(e.target.checked)}
              disabled={!isOrganizationEditing}
            />
          </InfoRow>
        </div>

        <Divider className={styles.divider} />

        <CollapsibleSection title="Branding" defaultExpanded>
          <div className={styles.infoGroupContainer}>
            <InfoRow label="Logo Icon">
              <ImageUploader
                hideIcon
                file={
                  branding.isLogoChanged || !branding.logoUrl
                    ? branding.logoUrl
                    : { preview: branding.logoUrl }
                }
                handleDropFile={handleDropFile}
                handleFileInputChange={handleFileInputChange}
                contentClassName={styles.logoContainer}
                dropZoneClassName={cx(styles.logoArea, {
                  [styles.hasFile]: !!branding.logoUrl,
                  [styles.editingBackground]: isOrganizationEditing,
                })}
                error={
                  showError && !branding.logoUrl && 'Please upload a logo in order to proceed.'
                }
                previewClassName={styles.logoImage}
                disabled={!isOrganizationEditing}
              />
            </InfoRow>
            <InfoRow label="Email">
              <TextField
                value={branding.mailFrom}
                style={{ width: 466 }}
                onChange={(e) => handleChangeBranding({ mailFrom: e.target.value })}
                disabled={!isOrganizationEditing}
                inputClassName={isOrganizationEditing && styles.bordered}
              />
            </InfoRow>
            <InfoRow label="Alias">
              <TextField
                value={branding.alias}
                style={{ width: 466 }}
                onChange={(e) => handleChangeBranding({ alias: e.target.value })}
                disabled={!isOrganizationEditing}
                inputClassName={isOrganizationEditing && styles.bordered}
              />
            </InfoRow>
            <InfoRow label="Support Url">
              <TextField
                value={branding.helpUrl}
                error={showError && !isValidWebLink(branding.helpUrl)}
                style={{ width: 466 }}
                onChange={(e) => handleChangeBranding({ helpUrl: e.target.value })}
                disabled={!isOrganizationEditing}
                inputClassName={isOrganizationEditing && styles.bordered}
              />
            </InfoRow>
          </div>
        </CollapsibleSection>

        <CollapsibleSection title="Webhooks" defaultExpanded>
          <EventsContent
            eventTypes={organizationStore.eventTypes}
            orgEventSubscriptions={eventSubscriptions}
            setOrgEventSubscriptions={(newEventSubscriptions) =>
              handleChangeProfile({
                eventSubscriptions: newEventSubscriptions,
              })
            }
            showError={showError}
            editing={isOrganizationEditing}
          />
        </CollapsibleSection>

        <CollapsibleSection title="Platforms" defaultExpanded>
          <PlatformsContent
            logins={logins}
            setLogins={(newLogins) =>
              handleChangeProfile({
                logins: newLogins,
              })
            }
            showError={showError}
            editing={isOrganizationEditing}
          />
        </CollapsibleSection>

        <CollapsibleSection title="Services" withDivider={false} defaultExpanded>
          <TreeListPanel
            multiSelect
            treeList={userStore.workflowTreeList}
            selectedValues={services}
            onChange={(services) => handleChangeProfile({ services })}
            disabled={!isOrganizationEditing}
          />
        </CollapsibleSection>
      </div>
    </div>
  );
};

export default observer(OrganizationContent);
