import { cloneDeep } from 'lodash';
import { observer } from 'mobx-react-lite';
import { withSize } from 'react-sizeme';

import { getBulkFiltersQuery, getBulkLogsEndpointURL, showErrorNotification } from '@/utils';
import { Config } from '@/config';
import { FilterType, MAX_QUERY_LENGTH } from '@/utils/constants';
import { userStore } from '@/store';
import { Checkbox, TextField, MultiSelect, Typography } from '@/components';

import styles from './BulkFilterPanel.module.css';
import { useBulkStore } from '../../stores/useBulkStore';

const PAGE_NAME = 'BulkTaskLog_FilterPanel';

export const BulkFilterPanel = withSize()(
  observer(({ size }) => {
    const { filterStore, tableStore } = useBulkStore();

    const handleSelectedValueChange = (filterKey, value) => {
      const newFilters = cloneDeep(filterStore.filters);
      newFilters[filterKey].selectedValues = value;

      const query = getBulkFiltersQuery(
        tableStore.pageNumber,
        tableStore.rowsPerPage,
        newFilters,
        filterStore.search,
      );
      const sort = { key: tableStore.sortKey, dir: tableStore.sortDirection };
      const url = getBulkLogsEndpointURL(
        `${Config.NEW_API}/Ticket/log/bulk`,
        userStore.organizationId,
        query,
        sort,
      );
      if (url.length < MAX_QUERY_LENGTH) {
        filterStore.updateSelectedValues(filterKey, value);
      } else {
        showErrorNotification("You can't select too many filters");
      }
    };

    return (
      <div className={styles.filtersGroup} id={`${PAGE_NAME}_container`}>
        {Object.keys(filterStore.filteredResult).map((filterKey) => {
          const {
            label,
            selectedValues: selectedValue,
            values,
            hasSearchBar,
            hasSelectAllClear,
            hasClear,
          } = filterStore.filteredResult[filterKey];
          const options = values.map((item) => ({
            value: item.value,
            label: item.label,
            type: item.type,
          }));

          if (filterKey === FilterType.lastUpdated) {
            return (
              <div key={filterKey} className={styles.filterItem} id={`${PAGE_NAME}_dropdown`}>
                <TextField
                  type="number"
                  placeholder={label}
                  value={selectedValue[0]}
                  onChange={(e) => handleSelectedValueChange(filterKey, [e.target.value])}
                  style={{ width: 'fit-content' }}
                  error={false}
                  id={`btn-filter-${filterKey}`}
                  disabled={false}
                />
              </div>
            );
          }

          if (filterKey === FilterType.service || filterKey === FilterType.activeChildren) {
            return (
              <div key={filterKey} className={styles.filterItem} id={`${PAGE_NAME}_dropdown`}>
                <MultiSelect
                  id={`btn-filter-${filterKey}`}
                  key={filterKey}
                  value={selectedValue}
                  placeholder={label}
                  options={options}
                  size="small"
                  isSearchBar={hasSearchBar}
                  hasClear={hasClear}
                  hasSelectAllClear={hasSelectAllClear}
                  style={{ width: 'fit-content' }}
                  renderValue={(selected, placeholder) => {
                    if (selected.length === 0 && placeholder) {
                      return <Typography variant="body2">{placeholder}</Typography>;
                    }
                    const selectedLabel =
                      options.find(({ value }) => value === selected?.[0])?.label || '';
                    const label = `${selectedLabel.slice(0, 20)}${
                      selectedLabel.length > 20 ? '...' : ''
                    }`;
                    if (selected.length > 1) {
                      return `${label} +${selected.length - 1} more`;
                    }
                    return label;
                  }}
                  onChange={(value) => handleSelectedValueChange(filterKey, value)}
                  MenuItemComponent={({ label, checked, match }) => {
                    return (
                      <>
                        <Checkbox
                          checked={checked}
                          id={`${PAGE_NAME}_checkboxItem`}
                          classes={{ formRoot: styles.menuItemCheckbox }}
                        />
                        <span>
                          {match ? (
                            <>
                              {label.slice(0, match[0])}
                              <span className={styles.highlight}>
                                {label.slice(match[0], match[1])}
                              </span>
                              {label.slice(match[1])}
                            </>
                          ) : (
                            label
                          )}
                        </span>
                      </>
                    );
                  }}
                />
              </div>
            );
          }
        })}
      </div>
    );
  }),
);
