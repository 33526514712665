import { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import { ListItemIcon, ListItemText, Typography } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '../Tooltip';
import TextLink from '../TextLink';
import palette from '@/config/theme/palette';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      marginTop: '30px !important',
      '&:before': {
        borderBottom: 'none !important',
      },
      '&:after': {
        borderBottom: 'none',
      },
    },
    '& .MuiSvgIcon-root': {
      position: 'absolute',
      right: '24px',
      color: `${theme.palette.secondary.darkBlue} !important`,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    '& .Mui-focused .MuiSvgIcon-root': {
      transform: 'rotate(180deg)',
    },
  },
  rootLabelHidden: {
    '& .MuiInputBase-root': {
      marginTop: '0px !important',
    },
  },
  smallMargin: {
    '& .MuiInputBase-root': {
      marginTop: '20px !important',
    },
  },
  rootError: {
    '& .MuiSvgIcon-root': {
      color: `${theme.palette.ui.error} !important`,
    },
  },
  rootDisabled: {
    '& .MuiInputBase-root .Mui-disabled': {
      paddingLeft: '0px !important',
      background: `${theme.palette.neutrals.grayBlue} !important`,
      borderColor: `${theme.palette.neutrals.grayBlue} !important`,
    },
    '& .MuiSvgIcon-root': {
      color: `${theme.palette.neutrals.greyMed} !important`,
      '&:hover': {
        cursor: 'default',
      },
    },
  },
  selectHelper: {
    fontFamily: 'Open Sans !important',
    fontWeight: '400 !important',
    fontSize: '14px !important',
    lineHeight: '19px !important',
    color: `${theme.palette.secondary.darkBlue} !important`,
    marginTop: '7px !important',
  },
  selectHelperError: {
    color: `${theme.palette.ui.error} !important`,
  },
  selectLabel: {
    fontFamily: 'Open Sans !important',
    fontWeight: '600 !important',
    fontSize: '14px !important',
    lineHeight: '19px !important',
    color: `${theme.palette.secondary.darkBlue} !important`,
    transform: 'none !important',
  },
  smallLabel: {
    fontSize: '13px !important',
    fontWeight: '400 !important',
  },
  selectLabelDisabled: {
    color: `${theme.palette.neutrals.greyMed} !important`,
  },
  selectLabelError: {
    color: `${theme.palette.ui.error} !important`,
  },
  dropdown: {
    display: 'flex !important',
    alignItems: 'center !important',
    fontFamily: 'Open Sans !important',
    fontSize: '16px !important',
    fontWeight: '400 !important',
    lineHeight: '150% !important',
    letterSpacing: '0.15px !important',
    height: '44px !important',
    padding: '0px 12x !important',
    borderRadius: '58px !important',
    color: `${theme.palette.secondary.darkBlue} !important`,
    backgroundColor: '#FFFFFF !important',
    border: '1px solid #FFFFFF !important',
    '&:focus': {
      backgroundColor: '#FFFFFF !important',
      border: `1px solid ${theme.palette.primary.purple} !important`,
    },
  },
  smallSize: {
    height: '22px !important',
    fontSize: '14px !important',
    padding: '0px 8px !important',
    borderRadius: '4px !important',
  },
  selected: {
    border: `1px solid ${theme.palette.primary.purple} !important`,
  },
  dropdownDark: {
    backgroundColor: `${theme.palette.neutrals.grayBlue} !important`,
    border: `1px solid ${theme.palette.neutrals.grayBlue} !important`,
  },
  dropdownError: {
    border: `1px solid ${theme.palette.ui.error} !important`,
    color: `${theme.palette.secondary.darkBlue} !important`,
    '&:focus': {
      border: `1px solid ${theme.palette.ui.error} !important`,
    },
  },
  dropdownDisabled: {
    border: `1px solid ${theme.palette.neutrals.lightGrey} !important`,
    backgroundColor: `${theme.palette.neutrals.lightGrey} !important`,
    '-webkit-text-fill-color': `${theme.palette.neutrals.grayBlueDark} !important`,
  },
  placeholder: {
    color: theme.palette.neutrals.greyMed,
  },
  value: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  icon: {
    transform: 'rotate(0deg) !important',
    right: '8px !important',
  },
  iconOpen: {
    transform: 'rotate(180deg) !important',
  },
  selectOptions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    backgroundColor: '#FFFFFF',
    padding: '0px 12px !important',
    '& .MuiMenuItem-root': {
      borderRadius: '8px',
      fontSize: '14px',
      '&:hover': {
        backgroundColor: `${theme.palette.neutrals.grayBlue} !important`,
      },
    },
    '& .Mui-selected': {
      backgroundColor: `${theme.palette.options.hover} !important`,
    },
  },
  paper: {
    marginTop: '8px !important',
    borderRadius: '16px !important',
    maxHeight: '300px !important',
    padding: '12px 0px !important',
    boxShadow: '0px 8px 25px rgba(0, 0, 0, 0.15) !important',
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      marginTop: 24,
      marginBottom: 24,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundClip: 'padding-box',
      background: 'rgba(16, 20, 30, 0.15)',
      borderRadius: 26,
    },
  },
  tooltip: {
    maxWidth: '170px !important',
  },
  tooltipIcon: {
    position: 'relative !important',
    right: 'unset !important',
    color: `${theme.palette.primary.purple} !important`,
    paddingLeft: 8,
    fontSize: '13px !important',
  },
}));
const PAGE_NAME = `DropDown`;

const Dropdown = ({
  size,
  options,
  isDarkTheme,
  label,
  tooltipText,
  error,
  placeholder,
  placeholderColor,
  helperText,
  disabled,
  MenuProps,
  ...rest
}) => {
  const styles = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const isLabelHidden = !label;
  const isSmallSize = size === 'small';

  return (
    <FormControl
      {...rest}
      error={error}
      disabled={disabled}
      variant="standard"
      classes={{
        root: cx(styles.root, {
          [styles.rootLabelHidden]: isLabelHidden,
          [styles.rootError]: error,
          [styles.rootDisabled]: disabled,
          [styles.smallMargin]: !isLabelHidden && isSmallSize,
        }),
      }}
    >
      {!isLabelHidden && (
        <InputLabel
          shrink
          htmlFor="component-selection-label"
          classes={{
            root: cx(styles.selectLabel, {
              [styles.selectLabelError]: error,
              [styles.smallLabel]: isSmallSize,
            }),
          }}
        >
          <Tooltip placement="right" arrow title={tooltipText} className={styles.tooltip}>
            <div style={{ display: 'flex', position: 'relative' }}>
              {label}
              {tooltipText && <InfoIcon className={styles.tooltipIcon} />}
            </div>
          </Tooltip>
        </InputLabel>
      )}
      <MuiSelect
        {...rest}
        error={error}
        id={`${PAGE_NAME}_MuiSelect`}
        aria-describedby="component-selection-select"
        IconComponent={ExpandMoreIcon}
        displayEmpty
        renderValue={(selected) => {
          if ((selected === undefined || selected === null) && placeholder) {
            return (
              <Typography
                variant="body2"
                className={styles.placeholder}
                style={{ color: placeholderColor }}
              >
                {placeholder}
              </Typography>
            );
          }
          return (
            <Typography variant="body2" className={styles.value}>
              {
                options.find(
                  (option) => option.value === selected || (option.id && option.id === selected),
                )?.label
              }
            </Typography>
          );
        }}
        classes={{
          select: cx(styles.dropdown, {
            [styles.dropdownDark]: isDarkTheme,
            [styles.dropdownError]: error,
            [styles.dropdownDisabled]: disabled,
            [styles.selected]: isOpen,
            [styles.smallSize]: isSmallSize,
          }),
          icon: cx(styles.icon, { [styles.iconOpen]: isOpen }),
        }}
        MenuProps={{
          classes: {
            paper: styles.paper,
          },
          MenuListProps: {
            classes: {
              root: styles.selectOptions,
            },
          },
          ...MenuProps,
        }}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
      >
        {options.map((option) => (
          <MenuItem
            key={`${option?.value}`}
            dense={isSmallSize}
            value={option?.value}
            id={`${PAGE_NAME}_MenuItem`}
          >
            <ListItemText>{option?.label}</ListItemText>
            {option.link && (
              <ListItemIcon style={{ justifyContent: 'center' }}>
                <TextLink
                  href={option.link}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: palette.secondary.darkBlue,
                  }}
                >
                  <LaunchIcon fontSize="small" />
                </TextLink>
              </ListItemIcon>
            )}
          </MenuItem>
        ))}
      </MuiSelect>
      {helperText && (
        <FormHelperText
          id="component-selection-helper"
          classes={{
            root: cx(styles.selectHelper, {
              [styles.selectHelperError]: error,
            }),
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

Dropdown.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
  isDarkTheme: PropTypes.bool,
  label: PropTypes.string,
  error: PropTypes.bool,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.any, label: PropTypes.string })),
  placeholderColor: PropTypes.string,
};

Dropdown.defaultProps = {
  size: 'small',
  isDarkTheme: false,
  label: '',
  error: false,
  placeholder: '',
  helperText: '',
  disabled: false,
  options: [],
  placeholderColor: '',
};

export default Dropdown;
