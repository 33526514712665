import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Popover } from '@mui/material';
import Typography from '../Typography';
import { useStyles } from './styles';
import { Checkbox } from '../Checkbox';
import { TRISTATE } from '@/utils/types';

const PAGE_NAME = 'MultiTriStateSelect';

const MultiTriStateSelect = ({
  size,
  value: values,
  options,
  placeholder,
  MenuProps,
  renderValue,
  onChange,
  disabled,
  classes,
  ...rest
}) => {
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const valueText = useMemo(() => {
    if (renderValue) return renderValue(values, placeholder);
    let firstLabel = options.find((item) => item.value === values[0])?.label ?? '';
    firstLabel = `${firstLabel.slice(0, 15)}${firstLabel.length > 15 ? '...' : ''}`;
    let valueLabel;
    if (values.length === 0 && placeholder) {
      valueLabel = placeholder;
    } else if (values.length > 1) {
      valueLabel = `${firstLabel} +${values.length - 1} more`;
    } else {
      valueLabel = firstLabel;
    }
    return valueLabel;
  }, [values, placeholder, options]);

  const handleOpen = (target) => {
    setAnchorEl(target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        id={rest.id}
        tabIndex={0}
        role="button"
        className={cx(
          styles.container,
          {
            [styles.selected]: Boolean(anchorEl),
            [styles.selectedContainer]: values.length > 0,
            [styles.disabled]: disabled,
            [styles.smallContainer]: size === 'small',
          },
          classes?.root,
        )}
        onClick={(event) => {
          if (!disabled) handleOpen(event.currentTarget);
        }}
      >
        <Typography
          id={`${PAGE_NAME}_selectedValue`}
          variant={size === 'small' ? 'body2' : 'body1'}
          style={{ whiteSpace: 'nowrap', minWidth: '120px' }}
        >
          {valueText}
        </Typography>
        <div className={cx(styles.moreIcon, { [styles.open]: Boolean(anchorEl) })}>
          <ExpandMoreIcon />
        </div>
      </div>

      <Popover
        anchorEl={anchorEl}
        id={`${PAGE_NAME}_Popover`}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
        classes={{ paper: styles.paper }}
      >
        <div className={styles.popoverContent}>
          <Typography className={styles.title}>{placeholder}</Typography>
          <div className={styles.menuContainer}>
            {options.map(({ value, label, match }, index) => (
              <MenuItem
                key={value}
                value={value}
                classes={{ root: styles.menuItem }}
                id="MuiSelect_MenuItem"
                onClick={() => {
                  const newValue = (values[index] + 1) % 3;
                  onChange(values.map((value, idx) => (idx === index ? newValue : value)));
                }}
              >
                <Checkbox
                  label={label}
                  checked={values[index] === TRISTATE.checked}
                  indeterminate={values[index] === TRISTATE.indeterminate}
                  classes={{ formRoot: classes?.item }}
                />
              </MenuItem>
            ))}
          </div>
        </div>
      </Popover>
    </>
  );
};

MultiTriStateSelect.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
  value: PropTypes.arrayOf(PropTypes.number),
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.any, label: PropTypes.string })),
  placeholderColor: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

MultiTriStateSelect.defaultProps = {
  size: 'small',
  value: [],
  placeholder: '',
  options: [],
  placeholderColor: '',
};

export default MultiTriStateSelect;
