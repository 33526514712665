import { useState } from 'react';
import cx from 'clsx';
import { Popover } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import styles from './StatusSelect.module.css';

const PAGE_NAME = 'StatusSelect';

export const StatusSelect = ({
  value,
  options,
  onChange,
  autoHeight = true,
  disabled,
  isDarkTheme,
  className,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const matchedOption = options.find((option) => option.value === value);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div id={PAGE_NAME}>
      <div
        tabIndex={0}
        role="button"
        className={cx(styles.statusContainer, {
          [styles.selected]: Boolean(anchorEl),
          [styles.statusDarkContainer]: isDarkTheme,
          [styles.statusDisabledContainer]: disabled,
          className,
        })}
        onClick={(event) => !disabled && setAnchorEl(event.currentTarget)}
      >
        <div id={`${PAGE_NAME}_StatusValue`} className={styles.statusValueContainer}>
          <div
            className={styles.statusValueIcon}
            style={{ backgroundColor: matchedOption?.color }}
          />
          <span className={styles.statusValueLabel}>{matchedOption?.label}</span>
        </div>

        <div className={cx(styles.moreIcon, { [styles.open]: Boolean(anchorEl) })}>
          <ExpandMoreIcon />
        </div>
      </div>

      <Popover
        anchorEl={anchorEl}
        id={`${PAGE_NAME}_Popover`}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
        classes={{ paper: cx(styles.paper, { [styles.paperAutoHeight]: autoHeight }) }}
      >
        <div className={styles.popoverContent}>
          <div className={styles.listWrapper} id={`${PAGE_NAME}_ListContainer`}>
            {options.map((option) => (
              <div
                key={option.value}
                id={`${PAGE_NAME}_MenuItem`}
                className={cx(styles.statusMenuItem, { [styles.selected]: value === option.value })}
                onClick={() => {
                  setAnchorEl(null);
                  onChange(option.value);
                }}
              >
                <div
                  className={styles.statusMenuItemIcon}
                  style={{ backgroundColor: option.color }}
                />
                <span className={styles.statusMenuItemLabel}>{option.label}</span>
              </div>
            ))}
          </div>
        </div>
      </Popover>
    </div>
  );
};
