import { Checkbox } from './Checkbox';

export const TriStateCheckbox = ({ triState, checked, onChange, ...props }) => {
  const handleChange = () => {
    let newValue = !checked;
    if (triState) {
      if (checked === true) newValue = null;
      else if (checked === null) newValue = false;
    }
    onChange(newValue);
  };

  return (
    <Checkbox
      checked={checked === true}
      indeterminate={triState && checked === false}
      onChange={handleChange}
      {...props}
    />
  );
};
