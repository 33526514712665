import * as fetchWithAuth from './fetchWithAuth';
import { FetchOptions } from './constants';
import { getLogsEndpointURL, getBulkLogsEndpointURL, getInternalTicketsEndpointURL } from '../utils';
import { Config } from '../config';
import { parseTaskLogFromResponse, parseBulkTaskLogFromResponse } from './parseUtils';

export const getTaskLogs = async (orgId, query, sort) => {
  const url = getInternalTicketsEndpointURL(`${Config.NEW_API}/Internal/tickets`, orgId, query, sort);

  return fetchWithAuth.get(url).then((resp) => ({
    pageCount: resp.total,
    currentPage: resp.page,
    logs: resp.tickets.map(parseTaskLogFromResponse),
  }));
};

export const getBulkTaskLogs = async (orgId, query, sort) => {
  const url = getBulkLogsEndpointURL(`${Config.NEW_API}/Ticket/log/bulk`, orgId, query, sort);

  return fetchWithAuth.get(url).then((resp) => ({
    pageCount: resp.total,
    currentPage: resp.page,
    logs: resp.map(parseBulkTaskLogFromResponse),
  }));
};

export const getTriggeredTickets = async (ticketId) => {
  const url = `${Config.NEW_API}/Schedule/triggered?ticketId=${ticketId}`;

  const triggeredTickets = await fetchWithAuth.get(url).then((resp) => resp);
  return triggeredTickets;
};

export const downloadLogsCSV = async (orgId, query, sort) => {
  const url = getLogsEndpointURL(`${Config.NEW_API}/Ticket/log/export`, orgId, query, sort, true);

  return fetchWithAuth
    .get(url, FetchOptions.stream)
    .then((res) => {
      const contentType = res.headers.get('Content-Type');

      if (contentType && contentType.includes('application/json')) {
        return res.json(); // handle JSON response
      } else {
        return res.blob(); // handle Blob stream response
      }
    })
    .then((data) => {
      if (data instanceof Blob) {
        return URL.createObjectURL(data);
      } else {
        return null;
      }
    });
};

export const executeTicket = async (ticketId) => {
  return await fetchWithAuth
    .post(`${Config.NEW_API}/Ticket/${ticketId}/execute`)
    .then((resp) => resp);
};
