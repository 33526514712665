import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { cloneDeep, debounce } from 'lodash';

import { Button, MoreButtonPopover, SearchField, Typography } from '@/components';
import { useSettingsStore } from '@/store';
import { FilterPanel } from '../Filter/FilterPanel';
import styles from './Header.module.css';

const PAGE_NAME = 'MemberHeader';

export const Header = observer(({ onCreateUser }) => {
  const { membersStore } = useSettingsStore();
  const [localFilters, setLocalFilters] = useState();
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (membersStore.filteredResult) setLocalFilters(cloneDeep(membersStore.filters));
  }, [membersStore.filteredResult]);

  const handleApplyFilters = () => {
    membersStore.updateFilterSelectedValues(localFilters);
  };

  const handleResetFilters = () => {
    membersStore.clearFilter();
    setLocalFilters(cloneDeep(membersStore.filters));
    setAnchorEl(null);
  };

  const handleChangeSearch = debounce((event) => {
    const { value } = event.target;
    membersStore.setSearch(value);
  }, 500);

  return (
    <div className={styles.headerContainer}>
      <SearchField
        placeholder="Search by id, name, email, path"
        value={membersStore.search}
        style={{ width: 270 }}
        classes={{
          root: styles.searchRoot,
          container: styles.searchContainer,
        }}
        onChange={handleChangeSearch}
        id={`${PAGE_NAME}_searchField`}
        helperText={`${membersStore.allFilteredMembers.length}/${membersStore.members.length}`}
      />
      <div className={styles.buttonsGroup}>
        <Button size="small" variant="primary" onClick={onCreateUser}>
          OnBoard
        </Button>
        <MoreButtonPopover
          tooltipText="Manage Filters"
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          onClose={handleApplyFilters}
        >
          <div className={styles.popoverHeader}>
            <Typography variant="subtitle1">Manage Filters</Typography>
            {membersStore.allFilteredCount > 0 && (
              <Button
                size="small"
                variant="tertiary"
                onClick={handleResetFilters}
                id={`${PAGE_NAME}_clearButton`}
              >
                Reset
              </Button>
            )}
          </div>
          <FilterPanel localFilters={localFilters} setLocalFilters={setLocalFilters} />
        </MoreButtonPopover>
      </div>
    </div>
  );
});
