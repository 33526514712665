import moment from 'moment-timezone';

export const SHORT_DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm a';
export const DEFAULT_DAYTIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DEFAULT_LOCAL_TIME_FORMAT = 'MM/DD/YYYY hh:mm:ss A';
const formats = [
  'YYYY-MM-DDTHH:mm:ssZ', // ISO 8601 format
  'YYYY/MM/DD HH:mm:ss', // Common format
  'YYYY/MM/DD hh:mm:ss A', // Common format with AM/PM
  'YYYY/MM/DD HH:mm', // Common format
  'YYYY/MM/DD hh:mm A', // Common format with AM/PM
  'MM/DD/YYYY HH:mm:ss', // US format
  'MM/DD/YYYY hh:mm:ss A', // US format with AM/PM
  'DD-MM-YYYY HH:mm:ss', // European format
  'DD/MM/YYYY HH:mm:ss', // European format
  'DD/MM/YYYY hh:mm:ss A', // European format with AM/PM
  'MM/DD/YYYY HH:mm',
  'MM/DD/YYYY hh:mm A',
  'DD/MM/YYYY HH:mm',
  'DD/MM/YYYY hh:mm A',
];
const USFormatCountries = ['US', 'CA', 'MX', 'BR', 'UNITED STATES', 'CANADA', 'MEXICO', 'BRAZIL'];

export function convertLocalDateTime(dateTime, region = 'US') {
  if (!dateTime) return null;
  const userLocale = region.toUpperCase();
  const localeFormats = USFormatCountries.includes(userLocale)
    ? [
        'MM/DD/YYYY HH:mm:ss',
        'MM/DD/YYYY hh:mm:ss A',
        'DD/MM/YYYY HH:mm:ss',
        'DD/MM/YYYY hh:mm:ss A',
        'MM/DD/YYYY HH:mm',
        'MM/DD/YYYY hh:mm A',
        'DD/MM/YYYY HH:mm',
        'DD/MM/YYYY hh:mm A',
      ]
    : [
        'DD/MM/YYYY HH:mm:ss',
        'DD/MM/YYYY hh:mm:ss A',
        'MM/DD/YYYY HH:mm:ss',
        'MM/DD/YYYY hh:mm:ss A',
        'DD/MM/YYYY HH:mm',
        'DD/MM/YYYY hh:mm A',
        'MM/DD/YYYY HH:mm',
        'MM/DD/YYYY hh:mm A',
      ];

  const allFormats = [...localeFormats, ...formats];
  for (let format of allFormats) {
    const parsedDate = moment(dateTime, format, true);
    if (parsedDate.isValid()) {
      return parsedDate.format(DEFAULT_DAYTIME_FORMAT);
    }
  }
  return moment(dateTime).format(DEFAULT_DAYTIME_FORMAT);
}

export function formatLocalDateTime(datetime, format) {
  if (!datetime) return null;
  const datetimeStr =
    datetime[datetime.length - 1] === 'Z' ? datetime.substr(0, datetime.length - 1) : datetime;
  if (format) {
    return moment(datetimeStr).format(format);
  }
  return moment(datetimeStr).format(DEFAULT_LOCAL_TIME_FORMAT);
}

export function getCurrentLocalDateTime(format) {
  if (format) {
    return moment().format(format);
  }
  return moment().format();
}

export function formatUTCDateTime(datetime, format) {
  if (!datetime) return null;
  if (format) {
    return moment(datetime).utc().format(format);
  }
  const timestr = moment(datetime).utc().format(DEFAULT_DAYTIME_FORMAT);
  return `${timestr}.000Z`;
}

export function getCurrentUTCDateTime(format) {
  if (format) {
    return moment().utc().format(format);
  }
  return moment().utc().format();
}

export function getEcdDiffDays(datetime) {
  if (!datetime) return '';
  const ecd = moment(datetime);
  const today = moment(getCurrentLocalDateTime());
  const diff = ecd.diff(today, 'days');
  return `${diff} day${diff > 1 ? 's' : ''}`;
}
