import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { isEqual, omit } from 'lodash';

import BulkTaskLog from './components/BulkTaskLog';
import { getBulkFiltersQuery, getBulkLogsEndpointURL } from '@/utils';
import { BASE_ROUTES, UrlSearch } from '@/utils/types';
import { Config } from '@/config';
import { FilterType, MAX_QUERY_LENGTH } from '@/utils/constants';
import { LoadingIndicator } from '@/components';
import { useSettingsStore, userStore } from '@/store';
import { InitFilter } from './stores/FilterStore';
import { useBulkStore } from './stores/useBulkStore';

const BulkPage = () => {
  const ticketsStore = useBulkStore();
  const { filterStore, tableStore, taskLogStore, routerStore } = ticketsStore;
  const { accountStore } = useSettingsStore();
  const initLoadingRef = useRef(true);
  const prevState = useRef(null);
  const prevParams = useRef();
  const urlParams = routerStore.getSearchChunkParams();

  useEffect(() => {
    return () => {
      ticketsStore.dispose();
      userStore.dispose();
    };
  }, []);

  useEffect(() => {
    filterStore.fetchFilters(accountStore.selectedAccount?.id);
  }, [accountStore.selectedAccount?.id]);

  useEffect(() => {
    if (routerStore.currentPage !== BASE_ROUTES.main) {
      routerStore.setCurrentPage(BASE_ROUTES.main);
      return;
    }
    if (prevParams.current && isEqual(prevParams.current, urlParams)) return;
    if (accountStore.allAccounts.length === 0) return;
    prevParams.current = urlParams;

    // NOTE: Update account
    const orgId = Number(urlParams[UrlSearch.organization]);
    if (orgId) {
      accountStore.onSelectAccount(orgId);
      accountStore.updateExpandedAccounts(
        accountStore.allAccounts,
        accountStore.selectedAccount?.path,
      );
    }

    // NOTE: Update table
    const pageNumber = Number(urlParams[UrlSearch.page]);
    const pageRows = Number(urlParams[UrlSearch.pageRows]);
    if (pageNumber && pageRows) {
      tableStore.setPageNumber(pageNumber);
      tableStore.setRowsPerPage(pageRows);
    }
    const sorts = urlParams[UrlSearch.sort]?.split('+');
    tableStore.setSortKey(sorts?.[0]);
    tableStore.setSortDirection(sorts?.[1]);

    // NOTE: Update filters
    filterStore.updateAllSelectedValues({
      [FilterType.account]: urlParams[FilterType.account]?.split('+').map(Number) ?? [],
      [FilterType.status]: urlParams[FilterType.status]?.split('+').map(Number) ?? [],
      [FilterType.service]: urlParams[FilterType.service]?.split('+') ?? [],
      [FilterType.type]: urlParams[FilterType.type]?.split('+') ?? [],
      [FilterType.owner]: urlParams[FilterType.owner]?.split('+').map(Number) ?? [],
      [FilterType.assign]: urlParams[FilterType.assign]?.split('+').map(Number) ?? [],
      [FilterType.automation]: urlParams[FilterType.automation]?.split('+') ?? [],
      [FilterType.viewBy]: urlParams[FilterType.viewBy]?.split('+') ?? [],
    });
    const searchTxt = urlParams[UrlSearch.search];
    filterStore.setSearch(searchTxt || '');
    initLoadingRef.current = false;
  }, [accountStore.allAccounts, urlParams]);

  useEffect(() => {
    if (initLoadingRef.current || !accountStore.selectedAccount?.id) return;
    // Update Uri
    const params = omit(
      routerStore.getSearchChunkParams(),
      Object.keys(InitFilter).concat(UrlSearch.search),
    );
    params[UrlSearch.organization] = accountStore.selectedAccount.id;
    params[UrlSearch.page] = tableStore.pageNumber;
    params[UrlSearch.pageRows] = tableStore.rowsPerPage;
    params[UrlSearch.sort] = `${tableStore.sortKey}+${tableStore.sortDirection}`;

    const filters = filterStore.filteredResult;
    Object.keys(filters).forEach((filterKey) => {
      const value = filters[filterKey]?.selectedValues;
      if (value?.length > 0) {
        params[filterKey] = value.join('+');
      }
    });
    if (filterStore.search) {
      params[UrlSearch.search] = filterStore.search;
    }

    const stringParams = Object.keys(params).reduce((acc, key) => {
      if (Number.isFinite(params[key])) return { ...acc, [key]: params[key].toString() };
      return { ...acc, [key]: params[key] };
    }, {});

    if (!isEqual(urlParams, stringParams)) {
      routerStore.setSearchChunkParams(params);
    }

    // Fetch service log
    const query = getBulkFiltersQuery(
      tableStore.pageNumber,
      tableStore.rowsPerPage,
      filterStore.filters,
      filterStore.search,
    );

    if (
      !prevState.current ||
      !isEqual(query, prevState.current.query) ||
      prevState.current.orgId !== accountStore.selectedAccount.id ||
      prevState.current.sortKey !== tableStore.sortKey ||
      prevState.current.sortDir !== tableStore.sortDirection
    ) {
      const orgId = accountStore.selectedAccount.id;
      prevState.current = {
        orgId,
        query,
        sortKey: tableStore.sortKey,
        sortDir: tableStore.sortDirection,
        search: filterStore.search,
      };
      if (tableStore.sortKey == 'updatedOn') tableStore.setSortKey('lastUpdated');
      const sort = { key: tableStore.sortKey, dir: tableStore.sortDirection };
      const url = getBulkLogsEndpointURL(`${Config.NEW_API}/Ticket/log/bulk`, orgId, query, sort);
      if (url.length < MAX_QUERY_LENGTH) {
        taskLogStore.fetchBulkTaskLog(orgId, query, sort);
      }
    }
  }, [
    accountStore.selectedAccount,
    filterStore.filteredResult,
    filterStore.search,
    tableStore.pageNumber,
    tableStore.rowsPerPage,
    tableStore.sortKey,
    tableStore.sortDirection,
    urlParams,
  ]);

  if (!userStore.organizationLogo) {
    return <LoadingIndicator fullScreen />;
  }

  return <BulkTaskLog />;
};

export default observer(BulkPage);
