import { useEffect, useState } from 'react';
import { Stack } from '@mui/material';

import { organizationApi } from '@/api';
import PasswordField from '../PasswordField';
import { TextCopyField } from './TextCopyField';

export const AmazonPlatform = ({
  name: platformNAME,
  username,
  onUsernameChange,
  showError,
  defaultValue,
  onChange,
  disabled,
}) => {
  const [templateData, setTemplateData] = useState(defaultValue ?? {});
  const [showCode, setShowCode] = useState(false);
  const [otpCode, setOtpCode] = useState('');

  useEffect(() => setTemplateData(defaultValue), [defaultValue]);

  const fetchOtpCode = async (isCopy) => {
    const code = await organizationApi.getOtpCode(templateData.OtpKey);
    setOtpCode(code);
    if (isCopy) handleCopyText(code);
  };

  useEffect(() => {
    if (disabled) fetchOtpCode();
  }, [showCode, disabled]);

  const handleChangeData = (field, e) => {
    const newData = { ...templateData, [field]: e.target.value };
    setTemplateData(newData);
    onChange(newData);
  };
  const handleChangeUsername = (e) => {
    onUsernameChange(e.target.value);
  };

  const handleCopyText = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <Stack spacing={1} id={`${platformNAME}_container`}>
      <Stack spacing={1} direction="row">
        <TextCopyField
          label="User Name*"
          value={username ?? ''}
          error={showError && !username}
          helperText={showError && !username ? 'Please provide your name.' : null}
          id={`${platformNAME}_userName`}
          size="small"
          disabled={disabled}
          classes={{ flex: 1 }}
          onChange={handleChangeUsername}
          onCopyClick={() => handleCopyText(username)}
        />
        <TextCopyField
          label="Account*"
          value={templateData.Account ?? ''}
          error={showError && !templateData.Account}
          classes={{ flex: 1 }}
          helperText={showError && !templateData.Account ? 'Please provide your Account.' : null}
          id={`${platformNAME}_account`}
          size="small"
          disabled={disabled}
          onChange={(e) => handleChangeData('Account', e)}
          onCopyClick={() => handleCopyText(templateData.Account)}
        />
      </Stack>
      <Stack spacing={1} direction="row">
        <TextCopyField
          label="Merchant*"
          value={templateData.Merchant ?? ''}
          classes={{ flex: 1 }}
          id={`${platformNAME}_userName`}
          size="small"
          disabled={disabled}
          onChange={(e) => handleChangeData('Merchant', e)}
          onCopyClick={() => handleCopyText(templateData.Merchant)}
        />
        <TextCopyField
          label="Marketplace*"
          error={showError && !templateData.Marketplace}
          value={templateData.Marketplace ?? ''}
          classes={{ flex: 1 }}
          onChange={(e) => handleChangeData('Marketplace', e)}
          id={`${platformNAME}_marketplace`}
          size="small"
          disabled={disabled}
          onCopyClick={() => handleCopyText(templateData.Marketplace)}
        />
      </Stack>
      <PasswordField
        value={templateData.Password ?? ''}
        label="Password"
        classes={{ flex: 1 }}
        id={`${platformNAME}_password`}
        size="small"
        disabled={disabled}
        onChange={(e) => handleChangeData('Password', e)}
        onCopyClick={() => handleCopyText(templateData.Password)}
      />
      <PasswordField
        label="One-Time Key"
        value={(disabled ? otpCode : templateData.OtpKey) ?? ''}
        setShowCode={setShowCode}
        classes={{ flex: 1 }}
        id={`${platformNAME}_optKey`}
        size="small"
        disabled={disabled}
        onChange={(e) => handleChangeData('OtpKey', e)}
        onCopyClick={() => {
          if (disabled) {
            fetchOtpCode(true);
          } else handleCopyText(templateData.OtpKey);
        }}
      />
    </Stack>
  );
};
