import { observer } from 'mobx-react-lite';
import cx from 'clsx';
import { makeStyles } from '@mui/styles';
import { TableRow } from '@mui/material';
import { BASE_ROUTES, ColumnType, UrlSearch } from '@/utils/types';
import { getMatch } from '@/utils';
import { StatusCell, TextCell } from '@/components';
import {
  DateTimeCell,
  TextLinkCell,
  TicketLinkCell,
} from '@/components/Table';
import { CheckboxCell } from '@/components/Table/CheckboxCell';
import { useBulkStore } from '../../stores/useBulkStore';
import styles from './BulkTaskLogTableRow.module.css'


const PAGE_NAME = 'BulkTaskLogTableRow';

export const BulkTaskLogTableRow = observer(({ log, isLastRow }) => {
  const { accountStore, tableStore, taskLogStore, filterStore } = useBulkStore();
  const ticketId = log[ColumnType.id];

  return (
    <TableRow
      hover
      data-role={`${PAGE_NAME}_container`}
      key={ticketId}
      className={cx(styles.tableRow, {
        [styles.selected]: log.selected,
        [styles.bodyLastLeftCell]: isLastRow,
        [styles.bodyLastRightCell]: isLastRow,
      })}
      onMouseEnter={() => tableStore.onMouseEnter(ticketId)}
      onMouseLeave={() => tableStore.onMouseLeave()}
      onClick={() => tableStore.setSelectedRow(ticketId)}
    >
      <CheckboxCell
        value={log.selected}
        onChange={(e) => {
          e.stopPropagation();
          taskLogStore.onToggleSelectTicket(log.id);
        }}
      />
      <TicketLinkCell
        column={ColumnType.id}
        link={log.ticketUrl}
        value={ticketId}
        showCopy={tableStore.hoveredRow === ticketId}
      />
      <TextCell
        id={`${PAGE_NAME}_organizationCell`}
        column={ColumnType.organization}
        value={log[ColumnType.organization]}
      />
      <TextCell
        id={`${PAGE_NAME}_workflowCell`}
        column={ColumnType.workflow}
        value={log[ColumnType.workflow]}
      />
      <TextCell
        id={`${PAGE_NAME}_titleCell`}
        column={ColumnType.title}
        value={log[ColumnType.title]}
      />
      <StatusCell 
        id={`${PAGE_NAME}_statusCell`} 
        value={log[ColumnType.state]} />

      <TextCell
        id={`${PAGE_NAME}_resolvedCell`}
        column={ColumnType.resolved}
        value={log[ColumnType.resolved]}
      />
      <TextCell
        id={`${PAGE_NAME}_totalCell`}
        column={ColumnType.total}
        value={log[ColumnType.total]}
      />
      <TextCell
        id={`${PAGE_NAME}_lastUpdated`}
        column={ColumnType.lastUpdated}
        value={log[ColumnType.lastUpdated]}
      />
      <TextCell
        id={`${PAGE_NAME}_ownedByname`}
        column={ColumnType.ownedByName}
        value={log[ColumnType.ownedByName]}
      />
      <TextCell
        id={`${PAGE_NAME}_assignedToName`}
        column={ColumnType.assignedToName}
        value={log[ColumnType.assignedToName]}
      />
      <TextCell
        id={`${PAGE_NAME}_commentedByName`}
        column={ColumnType.commentedByName}
        value={log[ColumnType.commentedByName]}
      />
    </TableRow>
  );
});
