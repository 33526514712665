import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import {
  CompactAttachment,
  CompactInfo,
  Dropdown,
  TextField,
  TypeaheadDropdown,
  Typography,
} from '@/components';
import { ObjectType, PriorityOptions } from '@/utils/types';
import { SHORT_DATE_FORMAT, formatLocalDateTime } from '@/utils';
import { useMainStore } from '../../stores/useMainStore';
import { ATTACHMENTS_FILED } from '@/utils/constants';

import styles from './Information.module.css';

const PAGE_NAME = 'InformationPanel';

export const InformationPanel = observer(({ variant, data, updateData, showError }) => {
  const {
    priority,
    remainingWork,
    ownedBy,
    assignedTo,
    createdByName,
    estimatedCompletion,
    createdDate,
    lastUpdated,
    resolvedDate,
  } = data;
  const dateItems = useMemo(
    () => [
      { label: 'Created Date', value: createdDate },
      { label: 'Updated Date', value: lastUpdated },
      { label: 'Resolved Date', value: resolvedDate },
      { label: 'Estimated Date', value: estimatedCompletion },
    ],
    [createdDate, lastUpdated, resolvedDate, estimatedCompletion],
  );
  const { detailStore } = useMainStore();
  const isQuestion = variant === ObjectType.question;
  const { isEditing, assignOptions } = detailStore;

  return (
    <div id={`${PAGE_NAME}_div`} className={styles.informationWrapper}>
      <CompactInfo label="Creator">
        <Typography variant="body2">{createdByName}</Typography>
      </CompactInfo>
      <CompactInfo label="Owner">
        <TypeaheadDropdown
          id={`${PAGE_NAME}_ownerDropdown`}
          value={ownedBy}
          options={assignOptions}
          onChange={(value) => detailStore.updateOwner(value)}
          isDarkTheme
          classes={{ container: styles.fixedWidth }}
        />
      </CompactInfo>
      <CompactInfo label="Assigned to">
        <TypeaheadDropdown
          id={`${PAGE_NAME}_assignDropdown`}
          value={assignedTo}
          options={assignOptions}
          onChange={(value) => detailStore.updateAssign(value)}
          isDarkTheme
          classes={{ container: styles.fixedWidth }}
        />
      </CompactInfo>
      {!isQuestion && (
        <>
          <CompactInfo label="Priority">
            <Dropdown
              size="small"
              id={`${PAGE_NAME}_priority_dropdown`}
              placeholder="Select Priority"
              value={priority}
              options={PriorityOptions}
              onChange={(e) => updateData({ priority: Number(e.target.value) })}
              disabled={!isEditing}
              sx={{ width: '92px' }}
            />
          </CompactInfo>
          <CompactInfo label="Quantity" last>
            {!isEditing ? (
              <Typography variant="body2">{remainingWork}</Typography>
            ) : (
              <TextField
                type="number"
                id={`${PAGE_NAME}_quantity_textfield`}
                placeholder="Input Quantity"
                error={showError && !remainingWork}
                value={remainingWork}
                onChange={(e) => detailStore.setQuantity(e.target.value)}
                sx={{ width: 72 }}
                disabled={!isEditing}
              />
            )}
          </CompactInfo>
        </>
      )}
      {dateItems.map(
        ({ label, value }) =>
          Boolean(value) && (
            <CompactInfo key={label} label={label}>
              <Typography variant="body2">
                {formatLocalDateTime(value, SHORT_DATE_FORMAT)}
              </Typography>
            </CompactInfo>
          ),
      )}
      <div id={`${PAGE_NAME}_uploadAttachment_container`}>
        {(isEditing || data.attachments?.length > 0) && (
          <CompactAttachment
            contentClassName={styles.uploader}
            files={data.attachments}
            onUpdateFile={(files) => updateData({ [ATTACHMENTS_FILED]: files })}
            disabled={!isEditing}
          />
        )}
      </div>
    </div>
  );
});
